export default {
  optionLine: {
    lineSetting: 'Line Setting',
    smoothCurve: 'Smooth Curve',
    stackedArea: 'Stacked Area',
    lineWidth: 'Line Width',
    dot: 'Dot',
    dotSize: 'Dot Size',
  },
};
