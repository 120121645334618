export default {
  optionText: {
    textContent: 'Text content',
    fontType: 'Font type',
    fontSize: 'Font size',
    fontColor: 'Font color',
    letterSpacing: 'Letter spacing',
    lineHeight: 'Line height',
    fontBackground: 'Font background',
    fontWeight: 'Font weight',
    alignment: 'Alignment',
    marqueeSetting: 'Marquee settings',
    enableMarquee: 'Enable',
    scrollingSpeed: 'Scrolling speed',
    hyperlinkSetting: 'Hyperlink settings',
    enableHyperlink: 'Enable',
    openMode: 'Open mode',
    hyperlinkURL: 'Hyperlink URL',
}
};
