export default {
  optionPictorialbar: {
    titleColor: 'Title Color',
    titleSize: 'Title Size',
    valueColor: 'Value Color',
    valueSize: 'Value Size',
    icon: 'Icon',
    iconSize: 'Icon Size',
    spacing: 'Spacing',
  },
};
