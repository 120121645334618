export default {
  groupHeader: {
    layer: 'Layer',
    operation: 'Operation',
    save: 'Save',
    shortcut_key: 'Shortcut Key',
    export_picture:'Export Picture',
    preview:'Preview',
    restore: 'Restore',
    import_export: 'Import/Export',
    share: 'Share',
    undo: 'Undo',
    redo: 'Redo',
    home: 'Home',
    saving_configuration: 'Saving configuration, please wait',
    save_configuration_success: 'Screen configuration saved successfully',
    save_configuration_failure: 'Failed to save screen configuration, please check server settings',
    export_screen_image: 'Export screen image?',
    prompt: 'Prompt',
    confirm: 'Confirm',
    cancel: 'Cancel',
    image_export_success: 'Image exported successfully',
  },
};
