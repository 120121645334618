export default {
  optionDatetime: {
    dateFormat: '时间格式',
    customFormat: '自定义格式',
    fontSpacing: '字体间距',
    fontSize: '字体大小',
    fontBackground: '字体背景',
    alignment: '对齐方式',
    fontWeight: '文字粗细',
    fontColor: '字体颜色',
  },
};
