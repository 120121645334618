import i18n from './locales';

window.$website = {
    // 是否demo环境
    isDemo: false,
    isDemoTip: '演示环境不允许操作',
    title: 'data数据大屏',
    name: '欢迎来到数据大屏',
    subName: '可视化数据大屏',
    // url: 'https://data.avuejs.com/api/blade-visual',
    //后端 api地址
    url: process.env.apiUrl,
    autoSave: false,
    autoSaveTime: 60000,
    componentsList: [{
      name: 'test',
      component: 'testComponents',
      option: 'testOption',
      data: true,
    },
  ],

  // 组件菜单数据
  baseList: [
    {
      // text:图表
      label: i18n.t('baseList.charts'),
      icon: 'icon-bar',
      children: [
        {
          // text：通用型
          label: i18n.t('baseList.common'),
          option: {
            // text：通用型
            name: i18n.t('baseList.common'),
            // text：通用型
            title: i18n.t('baseList.common'),
            icon: 'icon-bar',
            img: '/img/assets/text5.png',
            dataType: 1,
            dataMethod: 'get',
            data: {
              categories: [
                // text：苹果
                i18n.t('baseList.apple'),
                // text：三星
                i18n.t('baseList.samsung'),
                // text：小米
                i18n.t('baseList.xiaomi'),
                'oppo',
                'vivo',
              ],
              series: [
                {
                  // text:手机品牌
                  name: i18n.t('baseList.mobile_phone_brands'),
                  data: [1000879, 3400879, 2300879, 5400879, 3400879],
                },
              ],
            },
            echartFormatter:
              "(data,params)=>{\nconsole.log(data);\nreturn {\n            title: {\n\t\t\t\t textStyle: {\n\t\t\t\t\tfontWeight: 'normal',      \n\t\t\t\t\tcolor: '#fff'\n\t\t\t\t},\n                text: 'ECharts 入门示例'\n            },\n            tooltip: {},\n            legend: {\n                data:['销量'],\n\t\t\t\ttextStyle: {\n\t\t\t\t\tfontWeight: 'normal',      \n\t\t\t\t\tcolor: '#fff'\n\t\t\t\t},\n            },\n            xAxis: {\n                data: ['衬衫','羊毛衫','雪纺衫','裤子','高跟鞋','袜子']\n            },\n            yAxis: {},\n            series: [{\n                name: '销量',\n                type: 'bar',\n                data: [5, 20, 36, 10, 10, 20]\n            }]\n        };\n}",
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            url: '',
            component: {
              width: 600,
              height: 300,
              name: 'common',
              prop: 'common',
            },
          },
        },
        {
          label: 'datav',
          option: {
            name: 'datav',
            title: 'datav',
            icon: 'icon-datav',
            img: '/img/assets/text6.png',
            dataType: 0,
            dataMethod: 'get',
            data: {
              value: 66,
            },
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            echartFormatter: '(data,params)=>{\n  console.log(data);\n  return {\n    config:{\n\t    data: [66] \n\t  }\n  }\n}',
            component: {
              width: 150,
              height: 200,
              name: 'datav',
              prop: 'datav',
            },
            option: {
              is: 'dv-water-level-pond',
            },
          },
        },
        {
          // text:柱形图
          label: i18n.t('baseList.bar_chart'),
          option: {
            // text:柱形图
            name: i18n.t('baseList.bar_chart'),
            // text:柱形图
            title: i18n.t('baseList.bar_chart'),
            icon: 'icon-bar',
            img: '/img/assets/bar.png',
            data: {
              categories: [
                // text:苹果
                i18n.t('baseList.apple'),
                // text:三星
                i18n.t('baseList.samsung'),
                // text:小米
                i18n.t('baseList.xiaomi'),
                'oppo',
                'vivo',
              ],
              series: [
                {
                  // text:手机品牌
                  name: i18n.t('baseList.mobile_phone_brands'),
                  data: [1000879, 3400879, 2300879, 5400879, 3400879],
                },
              ],
            },
            dataType: 0,
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            component: {
              width: 600,
              height: 300,
              name: 'bar',
              prop: 'bar',
            },
            option: {
              gridX: 90,
              gridY: 47,
              gridX2: 43,
              gridY2: 25,
              legend: true,
              tipShow: true,
              xAxisShow: true,
              yAxisShow: true,
              barWidth: 30,
              barRadius: 8,
              barColor: [
                {
                  color1: '#83bff6',
                  color2: '#188df0',
                  postion: 90,
                },
                {
                  color1: '#23B7E5',
                  color2: '#564AA3',
                  postion: 50,
                },
              ],
              barMinHeight: 2,
            },
          },
        },
        {
          // text:折线图
          label: i18n.t('baseList.line_chart'),
          option: {
            // text:折线图
            name: i18n.t('baseList.line_chart'),
            // text:折线图
            title: i18n.t('baseList.line_chart'),
            icon: 'icon-line',
            img: '/img/assets/line.png',
            data: {
              categories: [
                // text：苹果
                i18n.t('baseList.apple'),
                // text：三星
                i18n.t('baseList.samsung'),
                // text：小米
                i18n.t('baseList.xiaomi'),
                'oppo',
                'vivo',
              ],
              series: [
                {
                  // text：手机品牌
                  name: i18n.t('baseList.mobile_phone_brands'),
                  data: [1000879, 3400879, 2300879, 5400879, 3400879],
                },
              ],
            },
            dataType: 0,
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            component: {
              width: 600,
              height: 300,
              name: 'line',
              prop: 'line',
            },
            option: {
              gridX: 84,
              gridY: 35,
              gridX2: 26,
              gridY2: 31,
              legend: true,
              tipShow: true,
              xAxisShow: true,
              yAxisShow: true,
              barColor: [
                {
                  color1: '#83bff6',
                  color2: '#188df0',
                  postion: 90,
                },
                {
                  color1: '#23B7E5',
                  color2: '#564AA3',
                  postion: 50,
                },
              ],
              symbolShow: true,
              symbolSize: 6,
              smooth: true,
            },
          },
        },
        {
          //text： 饼图
          label: i18n.t('baseList.pie_chart'),
          option: {
            //text： 饼图
            name: i18n.t('baseList.pie_chart'),
            //text： 饼图
            title: i18n.t('baseList.pie_chart'),
            icon: 'icon-pie',
            img: '/img/assets/pie.png',
            data: [
              {
                // text:苹果
                name: i18n.t('baseList.apple'),
                value: 1000879,
                url: 'http://www.baidu.com',
              },
              {
                // text:三星
                name: i18n.t('baseList.samsung'),
                value: 3400879,
                url: 'http://www.baidu.com',
              },
              {
                // text:小米
                name: i18n.t('baseList.xiaomi'),
                value: 2300879,
                url: 'http://www.baidu.com',
              },
              {
                name: 'oppo',
                value: 5400879,
                url: 'http://www.baidu.com',
              },
              {
                // text:大疆
                name: i18n.t('baseList.dji'),
                value: 3000,
                url: 'http://www.baidu.com',
              },
            ],
            dataType: 0,
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            component: {
              width: 600,
              height: 300,
              name: 'pie',
              prop: 'pie',
            },
            option: {
              legend: true,
              labelShow: true,
              barColor: [
                {
                  color1: '#83bff6',
                },
                {
                  color1: '#23B7E5',
                },
                {
                  color1: 'rgba(154, 168, 212, 1)',
                },
                {
                  color1: '#188df0',
                },
                {
                  color1: '#564AA3',
                },
              ],
            },
          },
        },
        {
          label: i18n.t('baseList.pictograph'),
          option: {
            title: i18n.t('baseList.pictograph'),
            name: i18n.t('baseList.pictograph'),
            icon: 'icon-pictorialBar',
            img: '/img/assets/bar2.png',
            data: [
              // text:小米

              {
                // text:苹果
                name: i18n.t('baseList.apple'),
                value: 1000879,
              },
              {
                // text:三星
                name: i18n.t('baseList.samsung'),
                value: 3400879,
              },
              {
                // text:小米
                name: i18n.t('baseList.xiaomi'),
                value: 2300879,
              },
              {
                name: 'oppo',
                value: 5400879,
              },
              {
                // text:大疆
                name: i18n.t('baseList.dji'),
                value: 3000,
              },
              {
                // text:抖音
                name: i18n.t('baseList.tiktok'),
                value: 2000,
              },
            ],
            dataType: 0,
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            component: {
              width: 600,
              height: 300,
              name: 'pictorialbar',
              prop: 'pictorialbar',
            },
            option: {
              symbolSize: 30,
              split: 30,
              symbol: '/img/source/source256.png',
              labelFontSize: '24',
              labelColor: '#4dffff',
              fontSize: 20,
              color: '#fff',
              gridX: 76,
              gridY: 4,
              gridX2: 127,
              gridY2: 0,
            },
          },
        },
        {
          // text:雷达图
          label: i18n.t('baseList.radar_chart'),
          option: {
            // text:雷达图
            title: i18n.t('baseList.radar_chart'),
            // text:雷达图
            name: i18n.t('baseList.radar_chart'),
            icon: 'icon-radar',
            img: '/img/assets/radar.png',
            dataType: 0,
            data: {
              indicator: [
                {
                  // text:销售
                  name: i18n.t('baseList.sales'),
                  max: 6500,
                },
                {
                  // text:管理
                  name: i18n.t('baseList.management'),
                  max: 16000,
                },
                {
                  // text:信息技术
                  name: i18n.t('baseList.information_technology'),
                  max: 30000,
                },
                {
                  // text:客服
                  name: i18n.t('baseList.customer_service'),
                  max: 38000,
                },
                {
                  // text:研发
                  name: i18n.t('baseList.research_and_development'),
                  max: 52000,
                },
                {
                  // text:市场
                  name: i18n.t('baseList.marketing'),
                  max: 25000,
                },
              ],
              series: [
                {
                  data: [
                    {
                      value: [4300, 10000, 28000, 35000, 50000, 19000],
                      name: '预算分配（Allocated Budget）',
                    },
                    {
                      value: [5000, 14000, 28000, 31000, 42000, 21000],
                      name: '实际开销（Actual Spending）',
                    },
                  ],
                },
              ],
            },
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            component: {
              width: 970,
              height: 400,
              name: 'radar',
              prop: 'radar',
            },
            option: {
              width: '100%',
              height: 600,
              color: '#fff',
              fontSize: 18,
              areaOpacity: 0.3,
              legend: true,
              tipShow: true,
              barColor: [
                {
                  color1: '#564AA3',
                },
                {
                  color1: '#188df0',
                },
              ],
            },
          },
        },
        {
          // text:散点图
          label: i18n.t('baseList.scatter_plot'),
          option: {
            // text:散点图
            title: i18n.t('baseList.scatter_plot'),
            // text:散点图
            name: i18n.t('baseList.scatter_plot'),
            icon: 'icon-scatter',
            img: '/img/assets/scatter.png',
            dataType: 0,
            data: [
              {
                data: [
                  [1, 8.04],
                  [2, 6.95],
                ],
              },
              {
                data: [
                  [1, 4.04],
                  [2, 3.95],
                ],
              },
            ],
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            component: {
              width: 600,
              height: 300,
              name: 'scatter',
              prop: 'scatter',
            },
            option: {
              gridX: 26,
              gridY: 12,
              gridX2: 14,
              gridY2: 30,
              lineSize: 25,
              tipShow: true,
              labelShow: true,
              legend: true,
              xAxisSplitLineShow: true,
              yAxisSplitLineShow: true,
              barColor: [
                {
                  postion: 0.2,
                  color1: '#91c7ae',
                },
                {
                  postion: 0.8,
                  color1: '#63869e',
                },
                {
                  postion: 1,
                  color1: '#c23531',
                },
              ],
            },
          },
        },
        {
          // text:漏斗图
          label: i18n.t('baseList.funnel_chart'),
          option: {
            // text:漏斗图
            title: i18n.t('baseList.funnel_chart'),
            // text:漏斗图
            name: i18n.t('baseList.funnel_chart'),
            icon: 'icon-funnel',
            img: '/img/assets/funnel.png',
            dataType: 0,
            data: [
              { value: 60, name: 'Visit' },
              { value: 40, name: 'Inquiry' },
              { value: 20, name: 'Order' },
              { value: 80, name: 'Click' },
              { value: 100, name: 'Show' },
            ],
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            component: {
              width: 450,
              height: 350,
              name: 'funnel',
              prop: 'funnel',
            },
            option: {
              legend: true,
              labelShow: true,
              tipShow: true,
              barColor: [
                {
                  color1: '#83bff6',
                },
                {
                  color1: '#23B7E5',
                },
                {
                  color1: 'rgba(154, 168, 212, 1)',
                },
                {
                  color1: '#188df0',
                },
                {
                  color1: '#564AA3',
                },
              ],
            },
          },
        },
        {
          // text:地图
          label: i18n.t('baseList.map'),
          option: {
            // text:地图
            name: i18n.t('baseList.map'),
            // text:地图
            title: i18n.t('baseList.map'),
            icon: 'icon-map',
            img: '/img/assets/map.png',
            dataType: 0,
            data: [
              {
                // text:测试坐标1
                name: i18n.t('baseList.coordinate_1'),
                value: 1,
                lng: 118.30078125,
                lat: 36.91915611148194,
                zoom: 1,
              },
              {
                // text:测试坐标2
                name: i18n.t('baseList.coordinate_2'),
                value: 1,
                lng: 112.21435546875,
                lat: 37.965854128749434,
                zoom: 1,
              },
            ],
            //text:名称
            // 应用地方在echart => packages => map 
            formatter: `(name,data)=>{\n  return name.data.name+name.data.value[2]\n}`,
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            component: {
              width: 800,
              height: 607,
              name: 'map',
              prop: 'map',
            },
            option: {
              tipBackgroundColor: 'rgba(13, 255, 255, .5)',
              tipColor: 'rgba(217, 38, 10, 1)',
              tipFontSize: '30',
              borderWidth: 1,
              scale: 87,
              type: 0,
              borderColor: '#0dffff',
              areaColor: 'rgba(6, 29, 51, 0.59)',
              banner: true,
              bannerTime: 3000,
              fontSize: 14,
              zoom: 0.5,
              roam: true,
              empAreaColor: 'rgba(35, 183, 229, 0.42)',
              empColor: 'rgba(217, 38, 10, 1)',
              index: '894edc1d-d2f0-4616-962c-8e61083ada71',
              mapData: 'https://data.bladex.vip/blade-visual/map/data?id=1235103352843448322',
              color: 'rgba(13, 255, 255, 1)',
            },
          },
        },
        {
          // text:矩形图
          label: i18n.t('baseList.rectangle_chart'),
          option: {
            // text:矩形图
            name: i18n.t('baseList.rectangle_chart'),
            // text:矩形图
            title: i18n.t('baseList.rectangle_chart'),
            icon: 'icon-juxing',
            img: '/img/assets/rectangle.png',
            data: [
              {
                // text:分类 1
                name: i18n.t('baseList.Classification_1'),
                value: 560,
              },
              {
                // text:分类 2
                name: i18n.t('baseList.Classification_2'),
                value: 500,
              },
              {
                // text:分类 3
                name: i18n.t('baseList.Classification_3'),
                value: 150,
              },
              {
                // text:分类 4
                name: i18n.t('baseList.Classification_4'),
                value: 140,
              },
              {
                // text:分类 5
                name: i18n.t('baseList.Classification_5'),
                value: 115,
              },
              {
                //text:分类 6
                name: i18n.t('baseList.Classification_6'),
                value: 95,
              },
              {
                //text:分类 7
                name: i18n.t('baseList.Classification_7'),
                value: 90,
              },
              {
                //text:分类 8
                name: i18n.t('baseList.Classification_8'),
                value: 75,
              },
              {
                //text:分类 9
                name: i18n.t('baseList.Classification_9'),
                value: 98,
              },
              {
                //text:分类 10
                name: i18n.t('baseList.Classification_10'),
                value: 60,
              },
              {
                //text:分类 11
                name: i18n.t('baseList.Classification_11'),
                value: 45,
              },
              {
                //text:分类 12
                name: i18n.t('baseList.Classification_12'),
                value: 40,
              },
              {
                //text:分类 13
                name: i18n.t('baseList.Classification_13'),
                value: 40,
              },
              {
                //text:分类 14
                name: i18n.t('baseList.Classification_14'),
                value: 35,
              },
              {
                //text:分类 15
                name: i18n.t('baseList.Classification_15'),
                value: 40,
              },
              {
                //text:分类 16
                name: i18n.t('baseList.Classification_16'),
                value: 40,
              },
              {
                //text:分类 17
                name: i18n.t('baseList.Classification_17'),
                value: 40,
              },
              {
                //text:分类 18
                name: i18n.t('baseList.Classification_18'),
                value: 30,
              },
              {
                //text:分类 19
                name: i18n.t('baseList.Classification_19'),
                value: 28,
              },
              {
                //text:分类 20
                name: i18n.t('baseList.Classification_20'),
                value: 16,
              },
            ],
            dataType: 0,
            component: {
              width: 754,
              height: 377,
              name: 'rectangle',
              prop: 'rectangle',
            },
            option: {
              tipShow: true,
              labelShow: true,
              labelShowColor: '#fff',
              labelShowFontSize: 14,
            },
          },
        },
        {
          // text:定时器
          label: i18n.t('baseList.timer'),
          option: {
            // text:定时器
            name: i18n.t('baseList.timer'),
            // text:定时器
            title: i18n.t('baseList.timer'),
            icon: 'icon-datetime',
            img: '/img/assets/time.png',
            echartFormatter: '(refs)=>{\n    console.log(refs)\n}',
            component: {
              width: 100,
              height: 100,
              name: 'time',
              prop: 'time',
            },
            option: {
              time: 0,
            },
          },
        },
        {
          // text:自定义Vue组件
          label: i18n.t('baseList.Custom_Vue_Component'),
          option: {
             // text:自定义Vue组件
            name: i18n.t('baseList.Custom_Vue_Component'),
             // text:自定义Vue组件
            title: i18n.t('baseList.Custom_Vue_Component'),
            icon: 'icon-bar',
            img: '/img/assets/text4.png',
            dataType: 0,
            data: {
              name: 'avue',
            },
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            component: {
              width: 800,
              height: 500,
              name: 'vue',
              prop: 'vue',
            },
            option: {
              content:
                '<template>\n    <div class="test">\n        <h1 @click="handleClick">{{$parent.dataChart}}<h1>\n    </div>\n</template>\n<script>\nexport default{\n    data(){\n        return{\n            \n        }\n    },\n    created(){\n        console.log(this.$parent);\n    },\n    methods:{\n        handleClick(){\n            this.$message.success(this.dataChart.name)\n        }\n    }\n}\n</script>\n<style>\n    .test{\n        text-align:center;\n        color:red;\n        font-size:40px;\n    }\n</style>',
            },
          },
        },
      ],
    },
    {
      // text：文字
      label: i18n.t('baseList.text'),
      icon: 'icon-text',
      children: [
        {
          // text：文本框
          label: i18n.t('baseList.text_box'),
          option: {
            // text：文本框
            title: i18n.t('baseList.text_box'),
            // text：文本框
            name: i18n.t('baseList.text_box'),
            icon: 'icon-text',
            img: '/img/assets/text.png',
            dataType: 0,
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            child: {
              index: [],
              paramName: '',
            },
            data: {
              // text：文本框
              value: i18n.t('baseList.text_box'),
            },
            component: {
              width: 100,
              height: 40,
              name: 'text',
              prop: 'text',
            },
            option: {
              textAlign: 'center',
              fontSize: 26,
              fontWeight: 'normal',
              color: '#fff',
            },
          },
        },
        {
          // text：跑马灯
          label: i18n.t('baseList.marquee'),
          option: {
            // text：跑马灯
            title: i18n.t('baseList.marquee'),
            // text：跑马灯
            name: i18n.t('baseList.marquee'),
            icon: 'icon-scroll',
            img: '/img/assets/text2.png',
            top: 0,
            left: 0,
            dataType: 0,
            data: {
              // text：跑马灯
              value: i18n.t('baseList.marquee'),
            },
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            component: {
              width: 100,
              height: 50,
              name: 'text',
              prop: 'text',
            },
            option: {
              scroll: true,
              speed: 70,
              textAlign: 'center',
              fontSize: 26,
              fontWeight: 'normal',
              color: '#fff',
            },
          },
        },
        {
          // text：超链接
          label: i18n.t('baseList.hyperlink'),
          option: {
            // text：超链接
            title: i18n.t('baseList.hyperlink'),
            // text：超链接
            name: i18n.t('baseList.hyperlink'),
            icon: 'icon-link',
            img: '/img/assets/text3.png',
            top: 0,
            left: 0,
            data: {
              // text：超链接
              value: i18n.t('baseList.hyperlink'),
            },
            dataType: 0,
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            component: {
              width: 100,
              height: 50,
              name: 'text',
              prop: 'text',
            },
            option: {
              link: true,
              linkHref: 'http://avue.top',
              linkTarget: '_self',
              textAlign: 'center',
              fontSize: 26,
              fontWeight: 'normal',
              color: '#fff',
            },
          },
        },
        {
          // text:实时时间
          label: i18n.t('baseList.real_time_time'),
          option: {
            // text:实时时间
            name: i18n.t('baseList.real_time_time'),
            // text:实时时间
            title: i18n.t('baseList.real_time_time'),
            icon: 'icon-datetime',
            img: '/img/assets/datetime.png',
            top: 78,
            left: 1604,
            component: {
              width: 250,
              height: 50,
              name: 'datetime',
              prop: 'datetime',
            },
            option: {
              format: 'yyyy-MM-dd hh:mm:ss',
              color: '#fff',
              textAlign: 'left',
              fontSize: 24,
              fontWeight: 'normal',
            },
          },
        },
      ],
    },
    {
      // text:媒体
      label: i18n.t('baseList.media'),
      icon: 'icon-img',
      children: [
        {
          // text:图片
          label: i18n.t('baseList.image'),
          option: {
            // text:图片
            title: i18n.t('baseList.image'),
            // text:图片
            name: i18n.t('baseList.image'),
            icon: 'icon-img',
            img: '/img/assets/img.png',
            top: 0,
            left: 0,
            dataType: 0,
            data: {
              value: '/img/img-default.png',
            },
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            component: {
              width: 180,
              height: 120,
              name: 'img',
              prop: 'img',
            },
            option: {
              duration: '1000',
            },
          },
        },
        {
          // text:图片框
          label:i18n.t('baseList.image_box'),
          option: {
            // text:图片框
            title:i18n.t('baseList.image_box'),
            // text:图片框
            name:i18n.t('baseList.image_box'),
            icon: 'icon-img',
            img: '/img/assets/imgborder.png',
            top: 0,
            left: 0,
            dataType: 0,
            data: '/img/border/border1.png',
            component: {
              width: 140,
              height: 140,
              name: 'imgborder',
              prop: 'imgborder',
            },
            option: {
              backgroundColor: 'rgba(180, 181, 198, 0.1)',
            },
          },
        },
        {
          // text:轮播图
          label: i18n.t('baseList.carousel'),
          option: {
             // text:轮播图
            title: i18n.t('baseList.carousel'),
             // text:轮播图
            name: i18n.t('baseList.carousel'),
            icon: 'icon-banner',
            img: '/img/assets/swiper.png',
            top: 0,
            left: 0,
            dataType: 0,
            data: [
              {
                value: 'https://img.alicdn.com/tfs/TB1v28TC8v0gK0jSZKbXXbK2FXa-1880-640.jpg',
              },
              {
                value: 'https://img.alicdn.com/tfs/TB1uevcCrj1gK0jSZFuXXcrHpXa-1880-640.jpg',
              },
              {
                value: 'http://www.w3school.com.cn/i/movie.ogg',
              },
            ],
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            component: {
              width: 670,
              height: 370,
              name: 'swiper',
              prop: 'swiper',
            },
            option: {
              type: '',
              interval: 5000,
              opacity: 100,
              indicator: 'none',
              autoplay: true,
              controls: true,
              loop: true,
            },
          },
        },
        {
          label: 'iframe',
          option: {
            title: 'iframe',
            name: 'iframe',
            icon: 'icon-iframe',
            img: '/img/assets/iframe.png',
            top: 0,
            left: 0,
            component: {
              width: 670,
              height: 370,
              option: {},
              name: 'iframe',
              prop: 'iframe',
            },
            dataType: 0,
            data: {
              value: 'https://avuejs.com',
            },
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            option: {},
          },
        },
        {
           // text:video播放器
          label: i18n.t('baseList.video_player'),
          option: {
            title: 'video',
            name: 'video',
            icon: 'icon-video',
            img: '/img/assets/video.png',
            top: 0,
            left: 0,
            dataType: 0,
            data: {
              value: 'http://www.w3school.com.cn/i/movie.ogg',
            },
            option: {
              autoplay: false,
              controls: true,
              loop: true,
              poster: '',
            },
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            component: {
              width: 670,
              height: 370,
              name: 'video',
              prop: 'video',
            },
          },
        },
        {
          // text:hls播放器
          label: i18n.t('baseList.hls_player'),
          option: {
            title: 'hls',
            name: 'hls',
            icon: 'icon-video',
            img: '/img/assets/video.png',
            top: 0,
            left: 0,
            dataType: 0,
            data: {
              value: 'https://open.ys7.com/v3/openlive/E10668433_1_1.m3u8?expire=1664027961&id=363444666731335680&t=346b970f0d5cb5d5b5e4734d966275a61fb9efd5038c7a75b7435423c5716908&ev=100',
            },
            option: {
              autoplay: true,
            },
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            component: {
              width: 670,
              height: 370,
              option: {},
              name: 'clapper',
              prop: 'clapper',
            },
          },
        },
      ],
    },
    {
      // text:指标
      label: i18n.t('baseList.indicator'),
      icon: 'icon-flop',
      children: [
        {
          // text:翻牌器
          label: i18n.t('baseList.flip_card'),
          option: {
            // text:翻牌器
            title: i18n.t('baseList.flip_card'),
            // text:翻牌器
            name: i18n.t('baseList.flip_card'),
            icon: 'icon-flop',
            img: '/img/assets/flop.png',
            top: 0,
            left: 0,
            dataType: 0,
            child: {
              index: [],
              paramName: '',
            },
            data: {
              value: '12345',
            },
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            component: {
              width: 445.6,
              height: 86.56,
              name: 'flop',
              prop: 'flop',
            },
            option: {
              textAlign: 'center',
              paddingLeft: 10,
              marginLeft: 10,
              border: true,
              borderColor: '#fff',
              borderWidth: 10,
              backgroundBorder: '/img/border/border1.png',
              fontSize: 50,
              color: '#afdef2',
              backgroundColor: '#132d4f',
            },
          },
        },
        {
          // text:颜色块
          label: i18n.t('baseList.color_block'),
          option: {
            // text:颜色块
            title: i18n.t('baseList.color_block'),
            // text:颜色块
            name: i18n.t('baseList.color_block'),
            icon: 'icon-flop',
            img: '/img/assets/flop2.png',
            child: {
              index: [],
              paramName: '',
            },
            top: 0,
            left: 0,
            dataType: 0,
            data: [
              {
                // text:某某某总人数
                backgroundColor: '#67C23A',
                prefixText: i18n.t('baseList.total_number_of_people'),
                value: '12345',
                suffixText: i18n.t('baseList.person'),
              },
              {
                // text:某某某总人数
                backgroundColor: '#409EFF',
                prefixText: i18n.t('baseList.total_number_of_people'),
                value: '12345',
                suffixText: i18n.t('baseList.person'),
              },
              {
                // text:某某某总人数
                backgroundColor: '#E6A23C',
                prefixText: i18n.t('baseList.total_number_of_people'),
                value: '12345',
                suffixText: i18n.t('baseList.person'),
              },
              {
                // text:某某某总人数
                backgroundColor: '#F56C6C',
                prefixText: i18n.t('baseList.total_number_of_people'),
                value: '12345',
                suffixText: i18n.t('baseList.person'),
              },
              {
                // text:某某某总人数
                backgroundColor: '#7232dd',
                prefixText: i18n.t('baseList.total_number_of_people'),
                value: '12345',
                suffixText: i18n.t('baseList.person'),
              },
              {
                // text:某某某总人数
                backgroundColor: 'blue',
                prefixText: i18n.t('baseList.total_number_of_people'),
                value: '12345',
                suffixText: i18n.t('baseList.person'),
              },
            ],
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            component: {
              width: 543.04,
              height: 196.28,
              name: 'flop',
              prop: 'flop',
            },
            option: {
              width: 180,
              whole: true,
              fontSize: 28,
              prefixFontSize: 13,
              backgroundColor: '',
              suffixFontSize: 18,
              marginTop: 10,
              marginLeft: 5,
              paddingTop: 10,
              paddingLeft: 30,
              color: '#fff',
              suffixInline: true,
              fontWeight: 'bolder',
              prefixText:'某某某总人数',
              suffixText:'人',
              value:'',
            },
          },
        },
        {
          // text:环形图
          label: i18n.t('baseList.ring_chart'),
          option: {
            // text:环形图
            title: i18n.t('baseList.ring_chart'),
            // text:环形图
            name: i18n.t('baseList.ring_chart'),
            icon: 'icon-circle',
            img: '/img/assets/progress.png',
            top: 0,
            left: 0,
            dataType: 0,
            data: {
              // text:人数增涨
              label: i18n.t('baseList.number_of_people_increase'),
              value: 40,
              data: 80,
            },
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            component: {
              width: 300,
              height: 300,
              name: 'progress',
              prop: 'progress',
            },
            option: {
              type: 'circle',
              color: '#60d3da',
              fontSize: 25,
              suffixColor: '#60d3da',
              strokeWidth: 20,
              fontWeight: 'normal',
              borderColor: '#60d3da',
              suffixFontSize: 30,
              FontWeight: 'normal',
            },
          },
        },
        {
          // text:进度条
          label: i18n.t('baseList.progress_bar'),
          option: {
            // text:进度条
            title: i18n.t('baseList.progress_bar'),
            // text:进度条
            name: i18n.t('baseList.progress_bar'),
            icon: 'icon-progress',
            img: '/img/assets/progress2.png',
            top: 0,
            left: 0,
            data: {
              // text:人数增涨
              label: i18n.t('baseList.number_of_people_increase'),
              value: 40,
              data: 80,
            },
            dataType: 0,
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            component: {
              width: 500,
              height: 100,
              name: 'progress',
              prop: 'progress',
            },
            option: {
              type: 'line',
              color: '#60d3da',
              fontSize: 25,
              suffixColor: '#60d3da',
              strokeWidth: 10,
              fontWeight: 'normal',
              borderColor: '#60d3da',
              suffixFontSize: 30,
              FontWeight: 'normal',
            },
          },
        },
        {
          // text:仪表盘
          label: i18n.t('baseList.gauge'),
          option: {
            // text:仪表盘
            title: i18n.t('baseList.gauge'),
            icon: 'icon-gauge',
            img: '/img/assets/gauge.png',
            // text:仪表盘
            name: i18n.t('baseList.gauge'),
            dataType: 0,
            data: {
              value: 4,
            },
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            component: {
              width: 390,
              height: 314,
              name: 'gauge',
              prop: 'gauge',
            },
            option: {
              lineSize: 15,
              axisLabelShow: true,
              axisLabelFontSize: 15,
              nameFontSize: 40,
              lineColor: '#eee',
              barColor: [
                {
                  color1: 'rgba(154, 168, 212, 1)',
                  postion: '0.2',
                },
                {
                  color1: '#23B7E5',
                  postion: '0.8',
                },
                {
                  color1: '#564AA3',
                  postion: '1',
                },
              ],
            },
          },
        },
        {
          // text:字符云
          label: i18n.t('baseList.word_cloud'),
          option: {
            // text:字符云
            title: i18n.t('baseList.word_cloud'),
            // text:字符云
            name: i18n.t('baseList.word_cloud'),
            icon: 'icon-wordCloud',
            img: '/img/assets/wordcloud.png',
            top: 0,
            left: 0,
            dataType: 0,
            data: [
              {
                // text:三星
                name: i18n.t('baseList.samsung'),
                value: '1',
              },
              {
                // text:小米
                name: i18n.t('baseList.xiaomi'),
                value: '10',
              },
              {
                // text:华为
                name: i18n.t('baseList.Huawei'),
                value: '20',
              },
              {
                // text:oppo
                name: 'oppo',
                value: '30',
              },
              {
                // text:抖音
                name: i18n.t('baseList.tiktok'),
                value: '40',
              },
              {
                // text:快手
                name: i18n.t('baseList.Kuaishou'),
                value: '50',
              },
              {
                // text:淘宝
                name: i18n.t('baseList.Taobao'),
                value: '60',
              },
              {
                // text:百度
                name: i18n.t('baseList.Baidu'),
                value: '70',
              },
              {
                // text:京东
                name: i18n.t('baseList.JD'),
                value: '80',
              },
              {
                // text:天猫
                name: i18n.t('baseList.Tmall'),
                value: '100',
              },
              {
                // text:字符1
                name: i18n.t('baseList.character1'),
                value: '110',
              },
              {
                // text:字符1
                name: i18n.t('baseList.character1'),
                value: '200',
                textStyle: {
                  color: 'red',
                },
              },
            ],
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            component: {
              name: 'wordcloud',
              prop: 'wordcloud',
              width: 600,
              height: 300,
            },
            option: {
              minFontSize: 30,
              maxFontSize: 80,
              split: 30,
              rotate: true,
            },
          },
        },
      ],
    },
    {
      // text:表格
      label: i18n.t('baseList.table'),
      icon: 'icon-table',
      children: [
        {
          // text:表格
          label: i18n.t('baseList.table'),
          option: {
            // text:表格
            title: i18n.t('baseList.table'),
            // text:表格
            name: i18n.t('baseList.table'),
            icon: 'icon-table',
            img: '/img/assets/table.png',
            top: 0,
            left: 0,
            dataType: 0,
            data: [
              {
                 // text:数据1
                type1: i18n.t('baseList.data1'),
                 // text:表格
                type2: i18n.t('baseList.table'),
              },
              {
                // text:数据2
                type1: i18n.t('baseList.data2'),
                 // text:表格
                type2: i18n.t('baseList.table'),
                 
              },
              {
                 // text:数据3
                type1: i18n.t('baseList.data3'),
                 // text:表格
                type2: i18n.t('baseList.table'),
              },
              {
                // text:数据4
                type1: i18n.t('baseList.data4'),
                // text:表格
                type2: i18n.t('baseList.table'),
              },
            ],
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            component: {
              width: 700,
              height: 350,
              name: 'table',
              prop: 'table',
            },
            option: {
              showHeader: true,
              columnShow: true,
              columnViews: true,
              headerBackground: '#050e18',
              headerColor: '#69bfe7',
              headerTextAlign: 'center',
              nthColor: '#09192c',
              bodyBackground: 'rgba(0, 0, 0, 0.01)',
              bodyColor: '#69bfe7',
              bodyTextAlign: 'center',
              align: 'center',
              headerAlign: 'center',
              header: false,
              scrollSpeed: 1,
              fontSize: 15,
              count: 4,
              index: true,
              column: [
                {
                   // text:列1
                  label: i18n.t('baseList.column1'),
                  prop: 'type1',
                  $index: 0,
                },
                {
                  // text:列2
                  label: i18n.t('baseList.column2'),
                  prop: 'type2',
                  $index: 1,
                },
                {
                   // text:列3
                  label: i18n.t('baseList.column3'),
                  prop: 'type3',
                  $index: 2,
                },
                {
                   // text:列4
                  label: i18n.t('baseList.column4'),
                  prop: 'type4',
                  $index: 3,
                },
              ],
              othColor: '#142a40',
              headerFontSize: 20,
              bodyFontSize: 18,
            },
          },
        },
        {
           // text:选项卡
          label: i18n.t('baseList.tab'),
          option: {
             // text:选项卡
            title: i18n.t('baseList.tab'),
             // text:选项卡
            name: i18n.t('baseList.tab'),
            icon: 'icon-tabs',
            img: '/img/assets/tabs.png',
            child: {
              index: [],
              paramName: '',
            },
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            dataType: 0,
            data: [
              {
                // text:选项卡1
                label:i18n.t('baseList.tab1'),
                value: '1',
              },
              {
                // text:选项卡2
                label: i18n.t('baseList.tab2'),
                value: '2',
              },
            ],
            component: {
              width: 356,
              height: 46,
              name: 'tabs',
              prop: 'tabs',
            },
            option: {
              type: 'tabs',
              color: '#eee',
              empColor: '#4dffff',
              fontSize: 20,
              split: 8,
              empBackgroundImage: '/img/banner/banner5.png',
              backgroundImage: '/img/banner/banner4.png',
            },
          },
        },
      ],
    },
    {
      // text:边框
      label: i18n.t('baseList.border'),
      icon: 'icon-biankuang',
      children: [
        {
          // text:边框1
          label: i18n.t('baseList.border1'),
          option: {
            // text:边框1
            title: i18n.t('baseList.border1'),
            name: 'datav',
            icon: 'icon-biankuang',
            img: '/img/assets/borderBox/borderBox_01.gif',
            top: 0,
            left: 0,
            data: {},
            component: {
              width: 300,
              height: 150,
              name: 'borderBox',
              prop: 'borderBox',
            },
            option: {
              type: 1,
              color1: '#83bff6',
              color2: '#00CED1',
              dur: undefined,
            },
          },
        },
        {
          // text:边框2
          label: i18n.t('baseList.border2'),
          option: {
            // text:边框2
            title: i18n.t('baseList.border2'),
            name: 'datav',
            icon: 'icon-biankuang',
            img: '/img/assets/borderBox/borderBox_02.png',
            top: 0,
            left: 0,
            data: {},
            component: {
              width: 300,
              height: 150,
              name: 'borderBox',
              prop: 'borderBox',
            },
            option: {
              type: 2,
              color1: '#83bff6',
              color2: '#00CED1',
              dur: undefined,
            },
          },
        },
        {
          // text:边框3
          label: i18n.t('baseList.border3'),
          option: {
            // text:边框3
            title: i18n.t('baseList.border3'),
            name: 'datav',
            icon: 'icon-biankuang',
            img: '/img/assets/borderBox/borderBox_03.png',
            top: 0,
            left: 0,
            data: {},
            component: {
              width: 300,
              height: 150,
              name: 'borderBox',
              prop: 'borderBox',
            },
            option: {
              type: 3,
              color1: '#83bff6',
              color2: '#00CED1',
              dur: undefined,
            },
          },
        },
        {
          // text:边框4
          label: i18n.t('baseList.border4'),
          option: {
            // text:边框4
            title: i18n.t('baseList.border4'),
            name: 'datav',
            icon: 'icon-biankuang',
            img: '/img/assets/borderBox/borderBox_04.png',
            top: 0,
            left: 0,
            component: {
              width: 300,
              height: 150,
              name: 'borderBox',
              prop: 'borderBox',
            },
            option: {
              type: 4,
              color1: '#83bff6',
              color2: '#00CED1',
              dur: undefined,
            },
          },
        },
        {
          // text:边框5
          label: i18n.t('baseList.border5'),
          option: {
            // text:边框5
            title: i18n.t('baseList.border5'),
            name: 'datav',
            icon: 'icon-biankuang',
            img: '/img/assets/borderBox/borderBox_05.png',
            top: 0,
            left: 0,
            data: {},
            component: {
              width: 300,
              height: 150,
              name: 'borderBox',
              prop: 'borderBox',
            },
            option: {
              type: 5,
              color1: '#83bff6',
              color2: '#00CED1',
              dur: undefined,
            },
          },
        },
        {
          // text:边框6
          label: i18n.t('baseList.border6'),
          option: {
            // text:边框6
            title: i18n.t('baseList.border6'),
            name: 'datav',
            icon: 'icon-biankuang',
            img: '/img/assets/borderBox/borderBox_06.png',
            top: 0,
            left: 0,
            data: {},
            component: {
              width: 300,
              height: 150,
              name: 'borderBox',
              prop: 'borderBox',
            },
            option: {
              type: 6,
              color1: '#83bff6',
              color2: '#00CED1',
              dur: undefined,
            },
          },
        },
        {
          // text:边框7
          label: i18n.t('baseList.border7'),
          option: {
            // text:边框7
            title: i18n.t('baseList.border7'),
            name: 'datav',
            icon: 'icon-biankuang',
            img: '/img/assets/borderBox/borderBox_07.png',
            top: 0,
            left: 0,
            data: {},
            component: {
              width: 300,
              height: 150,
              name: 'borderBox',
              prop: 'borderBox',
            },
            option: {
              type: 7,
              color1: '#83bff6',
              color2: '#00CED1',
              dur: undefined,
            },
          },
        },
        {
          // text:边框8
          label: i18n.t('baseList.border8'),
          option: {
            // text:边框8
            title: i18n.t('baseList.border8'),
            name: 'datav',
            icon: 'icon-biankuang',
            img: '/img/assets/borderBox/borderBox_08.gif',
            top: 0,
            left: 0,
            data: {},
            component: {
              width: 300,
              height: 150,
              name: 'borderBox',
              prop: 'borderBox',
            },
            option: {
              type: 8,
              color1: '#83bff6',
              color2: '#00CED1',
              dur: undefined,
            },
          },
        },
        {
          // text:边框9
          label: i18n.t('baseList.border9'),
          option: {
            // text:边框9
            title: i18n.t('baseList.border9'),
            name: 'datav',
            icon: 'icon-biankuang',
            img: '/img/assets/borderBox/borderBox_09.png',
            top: 0,
            left: 0,
            data: {},
            component: {
              width: 300,
              height: 150,
              name: 'borderBox',
              prop: 'borderBox',
            },
            option: {
              type: 9,
              color1: '#83bff6',
              color2: '#00CED1',
              dur: undefined,
            },
          },
        },
        {
          // text:边框10
          label: i18n.t('baseList.border10'),
          option: {
            // text:边框10
            title: i18n.t('baseList.border10'),
            name: 'datav',
            icon: 'icon-biankuang',
            img: '/img/assets/borderBox/borderBox_10.png',
            top: 0,
            left: 0,
            data: {},
            component: {
              width: 300,
              height: 150,
              name: 'borderBox',
              prop: 'borderBox',
            },
            option: {
              type: 10,
              color1: '#83bff6',
              color2: '#00CED1',
              dur: undefined,
            },
          },
        },
        {
          // text:边框11
          label: i18n.t('baseList.border11'),
          option: {
            // text:边框11
            title: i18n.t('baseList.border11'),
            name: 'datav',
            icon: 'icon-biankuang',
            img: '/img/assets/borderBox/borderBox_11.png',
            top: 0,
            left: 0,
            data: {},
            component: {
              width: 300,
              height: 150,
              name: 'borderBox',
              prop: 'borderBox',
            },
            option: {
              type: 11,
              color1: '#83bff6',
              color2: '#00CED1',
              dur: undefined,
            },
          },
        },
        {
          // text:边框12
          label: i18n.t('baseList.border12'),
          option: {
            // text:边框12
            title: i18n.t('baseList.border12'),
            name: 'datav',
            icon: 'icon-biankuang',
            img: '/img/assets/borderBox/borderBox_12.png',
            top: 0,
            left: 0,
            data: {},
            component: {
              width: 300,
              height: 150,
              name: 'borderBox',
              prop: 'borderBox',
            },
            option: {
              type: 12,
              color1: '#83bff6',
              color2: '#00CED1',
              dur: undefined,
            },
          },
        },
      ],
    },
    {
      // text:datav例子
      label:  i18n.t('baseList.datav_example'),
      icon: 'icon-datav',
      children: [
        {
          // text:滚动排名
          label:  i18n.t('baseList.scroll_ranking'),
          option: {
            // text:滚动排名
            name:  i18n.t('baseList.scroll_ranking'),
            // text:滚动排名
            title:  i18n.t('baseList.scroll_ranking'),
            icon: 'icon-datav',
            img: '/img/assets/table1.gif',
            dataType: 0,
            data: [
              {
                // text:周口
                name:  i18n.t('baseList.zhoukou'),
                value: 55,
              },
              {
                // text:南阳
                name:  i18n.t('baseList.nanyang'),
                value: 120,
              },
              {
                // text:西峡
                name:  i18n.t('baseList.xixia'),
                value: 78,
              },
              {
                // text:驻马店
                name:  i18n.t('baseList.zhumadian'),
                value: 66,
              },
              {
                // text:新乡
                name:  i18n.t('baseList.xinxiang'),
                value: 80,
              },
              {
                // text:信阳
                name:  i18n.t('baseList.xinyang'),
                value: 45,
              },
              {
                // text:漯河
                name:  i18n.t('baseList.luohe'),
                value: 29,
              },
            ],
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            echartFormatter: "(data)=>{\n  return {\n    config:{\n\t    data: data,\n      //carousel: 'page',\n      valueFormatter ({ value }) {\n        return value+'单位'\n      }\n\t  }\n  }\n}",
            component: {
              width: 652.35,
              height: 248.45,
              name: 'datav',
              prop: 'datav',
            },
            option: {
              is: 'dv-scroll-ranking-board',
            },
          },
        },
        {
          // text:胶囊排名
          label: i18n.t('baseList.capsule_ranking'),
          option: {
            // text:胶囊排名
            name: i18n.t('baseList.capsule_ranking'),
            // text:胶囊排名
            title: i18n.t('baseList.capsule_ranking'),
            icon: 'icon-datav',
            img: '/img/assets/table3.png',
            dataType: 0,
            data: [
              {
                // text:周口
                name:  i18n.t('baseList.zhoukou'),
                value: 55,
              },
              {
                // text:南阳
                name:  i18n.t('baseList.nanyang'),
                value: 120,
              },
              {
                // text:西峡
                name:  i18n.t('baseList.xixia'),
                value: 78,
              },
              {
                // text:驻马店
                name:  i18n.t('baseList.zhumadian'),
                value: 66,
              },
              {
                // text:新乡
                name:  i18n.t('baseList.xinxiang'),
                value: 80,
              },
              {
                // text:信阳
                name:  i18n.t('baseList.xinyang'),
                value: 45,
              },
              {
                // text:漯河
                name:  i18n.t('baseList.luohe'),
                value: 29,
              },
            ],
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            echartFormatter: "(data)=>{\n  return {\n    config:{\n\t    data: data,\n      colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9', '#96bfff'],\n      unit: '单位',\n      showValue: true\n\t  }\n  }\n}",
            component: {
              width: 652.35,
              height: 248.45,
              name: 'datav',
              prop: 'datav',
            },
            option: {
              is: 'dv-capsule-chart',
            },
          },
        },
        {
          // text:水位图
          label:  i18n.t('baseList.water_level_chart'),
          option: {
            // text:水位图
            name:  i18n.t('baseList.water_level_chart'),
            title: 'datav',
            icon: 'icon-datav',
            img: '/img/assets/datav/datav1.png',
            dataType: 0,
            data: {
              value: 66,
            },
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            echartFormatter: "(data)=>{\n  return {\n    config:{\n\t    data: [data.value],\n      shape: 'roundRect'\n\t  }\n  }\n}",
            component: {
              width: 150,
              height: 200,
              name: 'datav',
              prop: 'datav',
            },
            option: {
              is: 'dv-water-level-pond',
            },
          },
        },
        {
          // text:进度池
          label:  i18n.t('baseList.progress_pool'),
          option: {
            // text:进度池
            name:  i18n.t('baseList.progress_pool'),
            title: 'datav',
            icon: 'icon-datav',
            img: '/img/assets/datav/datav2.png',
            dataType: 0,
            data: {
              value: 66,
            },
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            echartFormatter: '(data)=>{\n  return {\n    config:{\n      value: data.value,\n      borderWidth: 5,\n      borderRadius: 10,\n      borderGap: 5,\n      lineDash: [10, 2],\n      localGradient: true\n    }\n  }\n}',
            component: {
              width: 369,
              height: 153,
              name: 'datav',
              prop: 'datav',
            },
            option: {
              is: 'dv-percent-pond',
            },
          },
        },
        {
          // text:锥形柱图
          label:  i18n.t('baseList.cone_column_chart'),
          option: {
            // text:锥形柱图
            name:  i18n.t('baseList.cone_column_chart'),
            title: 'datav',
            icon: 'icon-datav',
            img: '/img/assets/datav/datav3.png',
            dataType: 0,
            data: [
              {
                // text:周口
                name: i18n.t('baseList.zhoukou'),
                value: 55,
              },
              {
                // text:南阳
                name: i18n.t('baseList.nanyang'),
                value: 120,
              },
              {
                // text:西峡
                name: i18n.t('baseList.xixia'),
                value: 71,
              },
              {
                // text:驻马店
                name:  i18n.t('baseList.zhumadian'),
                value: 66,
              },
              {
                // text:新乡
                name:  i18n.t('baseList.xinxiang'),
                value: 80,
              },
              {
                // text:信阳
                name:  i18n.t('baseList.xinyang'),
                value: 35,
              },
              {
                // text:漯河
                name:  i18n.t('baseList.luohe'),
                value: 15,
              },
            ],
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            echartFormatter:
              "(data)=>{\n  return {\n    config:{\n      data:data,\n      img: [\n        '/img/conicalColumnChart/1st.png',\n        '/img/conicalColumnChart/2st.png',\n        '/img/conicalColumnChart/3st.png',\n        '/img/conicalColumnChart/4st.png',\n        '/img/conicalColumnChart/5st.png',\n        '/img/conicalColumnChart/6st.png',\n        '/img/conicalColumnChart/7st.png'\n      ],\n      showValue: true\n    }\n  }\n}",
            component: {
              width: 549.18,
              height: 262.98,
              name: 'datav',
              prop: 'datav',
            },
            option: {
              is: 'dv-conical-column-chart',
            },
          },
        },
        {
          // text:飞线图
          label:  i18n.t('baseList.flight_line_chart'),
          option: {
            // text:飞线图
            name:  i18n.t('baseList.flight_line_chart'),
            title: 'datav',
            icon: 'icon-datav',
            img: '/img/assets/datav/datav4.png',
            dataType: 0,
            data: {
              points: [
                {
                  // text:郑州
                  name:  i18n.t('baseList.zhengzhou'),
                  coordinate: [0.48, 0.35],
                  halo: {
                    show: true,
                  },
                  icon: {
                    src: '/img/flylineChart/mapCenterPoint.png',
                    width: 30,
                    height: 30,
                  },
                  text: {
                    show: false,
                  },
                },
                {
                  // text:新乡
                  name:  i18n.t('baseList.xinxiang'),
                  coordinate: [0.52, 0.23],
                },
                {
                  // text:焦作
                  name:  i18n.t('baseList.jiaozuo'),
                  coordinate: [0.43, 0.29],
                },
                {
                  // text:开封
                  name:  i18n.t('baseList.kaifeng'),
                  coordinate: [0.59, 0.35],
                },
                {
                  // text:许昌
                  name:  i18n.t('baseList.xuchang'),
                  coordinate: [0.53, 0.47],
                },
                {
                  // text:平顶山
                  name:  i18n.t('baseList.pingdingshan'),
                  coordinate: [0.45, 0.54],
                },
                {
                  // text:洛阳
                  name:  i18n.t('baseList.luoyang'),
                  coordinate: [0.36, 0.38],
                },
                {
                  // text:周口
                  name:  i18n.t('baseList.zhoukou'),
                  coordinate: [0.62, 0.55],
                  halo: {
                    show: true,
                    color: '#8378ea',
                  },
                },
                {
                  // text:漯河
                  name:  i18n.t('baseList.luohe'),
                  coordinate: [0.56, 0.56],
                },
                {
                  // text:南阳
                  name:  i18n.t('baseList.nanyang'),
                  coordinate: [0.37, 0.66],
                  halo: {
                    show: true,
                    color: '#37a2da',
                  },
                },
                {
                  // text:信阳
                  name:  i18n.t('baseList.xinyang'),
                  coordinate: [0.55, 0.81],
                },
                {
                  // text:驻马店
                  name: i18n.t('baseList.zhumadian'),
                  coordinate: [0.55, 0.67],
                },
                {
                  // text:济源
                  name:  i18n.t('baseList.jiyuan'),
                  coordinate: [0.37, 0.29],
                },
                {
                  // text:三门峡
                  name:  i18n.t('baseList.sanmenxia'),
                  coordinate: [0.2, 0.36],
                },
                {
                  // text:商丘
                  name:  i18n.t('baseList.shangqiu'),
                  coordinate: [0.76, 0.41],
                },
                {
                  // text:鹤壁
                  name:  i18n.t('baseList.hebi'),
                  coordinate: [0.59, 0.18],
                },
                {
                  // text:濮阳
                  name:  i18n.t('baseList.puyang'),
                  coordinate: [0.68, 0.17],
                },
                {
                  // text:安阳
                  name:  i18n.t('baseList.anyang'),
                  coordinate: [0.59, 0.1],
                },
              ],
              lines: [
                {
                  // text:新乡
                  source:  i18n.t('baseList.xinxiang'),
                  // text:郑州
                  target:  i18n.t('baseList.zhengzhou'),
                },
                {
                  // text:焦作
                  source:  i18n.t('baseList.jiaozuo'),
                  // text:郑州
                  target:  i18n.t('baseList.zhengzhou'),
                },
                {
                  // text:开封
                  source:  i18n.t('baseList.kaifeng'),
                  // text:郑州
                  target:  i18n.t('baseList.zhengzhou'),
                },
                {
                  // text:周口
                  source:  i18n.t('baseList.zhoukou'),
                  // text:郑州
                  target:  i18n.t('baseList.zhengzhou'),
                  color: '#fb7293',
                  width: 2,
                },
                {
                  // text:南阳
                  source:  i18n.t('baseList.nanyang'),
                  // text:郑州
                  target: i18n.t('baseList.zhengzhou'),
                  color: '#fb7293',
                  width: 2,
                },
                {
                  // text:济源
                  source:  i18n.t('baseList.jiyuan'),
                  // text:郑州
                  target:  i18n.t('baseList.zhengzhou'),
                },
                {
                  // text:三门峡
                  source:  i18n.t('baseList.sanmenxia'),
                  // text:郑州
                  target:  i18n.t('baseList.zhengzhou'),
                },
                {
                  // text:商丘
                  source:  i18n.t('baseList.shangqiu'),
                  // text:郑州
                  target:  i18n.t('baseList.zhengzhou'),
                },
                {
                  // text:鹤壁
                  source:  i18n.t('baseList.hebi'),
                  // text:郑州
                  target: i18n.t('baseList.zhengzhou'),
                },
                {
                  // text:濮阳
                  source:  i18n.t('baseList.puyang'),
                  // text:郑州
                  target:  i18n.t('baseList.zhengzhou'),
                },
                {
                  // text:安阳
                  source:  i18n.t('baseList.anyang'),
                  // text:郑州
                  target:  i18n.t('baseList.zhengzhou'),
                },
                {
                  // text:许昌
                  source:  i18n.t('baseList.xuchang'),
                  // text:南阳
                  target:  i18n.t('baseList.nanyang'),
                  color: '#37a2da',
                },
                {
                  // text:平顶山
                  source:  i18n.t('baseList.pingdingshan'),
                  // text:南阳
                  target:  i18n.t('baseList.nanyang'),
                  color: '#37a2da',
                },
                {
                  // text:洛阳
                  source:  i18n.t('baseList.luoyang'),
                  // text:南阳
                  target:  i18n.t('baseList.nanyang'),
                  color: '#37a2da',
                },
                {
                  // text:驻马店
                  source:  i18n.t('baseList.zhumadian'),
                  // text:周口
                  target:  i18n.t('baseList.zhoukou'),
                  color: '#8378ea',
                },
                {
                  // text:信阳
                  source:  i18n.t('baseList.xinyang'),
                  // text:周口
                  target:  i18n.t('baseList.zhoukou'),
                  color: '#8378ea',
                },
                {
                  // text:漯河
                  source:  i18n.t('baseList.luohe'),
                  // text:周口
                  target: i18n.t('baseList.zhoukou'),
                  color: '#8378ea',
                },
              ],
            },
            lines: [
              {
                // text:新乡
                source:  i18n.t('baseList.xinxiang'),
                // text:郑州
                target:  i18n.t('baseList.zhengzhou'),
              },
              {
                // text:焦作
                source:  i18n.t('baseList.jiaozuo'),
                // text:郑州
                target:  i18n.t('baseList.zhengzhou'),
              },
              {
                // text:开封
                source:  i18n.t('baseList.kaifeng'),
                // text:郑州
                target:  i18n.t('baseList.zhengzhou'),
              },
              {
                // text:周口
                source:  i18n.t('baseList.zhoukou'),
                // text:郑州
                target:  i18n.t('baseList.zhengzhou'),
                color: '#fb7293',
                width: 2,
              },
              {
                // text:南阳
                source:  i18n.t('baseList.nanyang'),
                // text:郑州
                target:  i18n.t('baseList.zhengzhou'),
                color: '#fb7293',
                width: 2,
              },
              {
                // text:济源
                source:  i18n.t('baseList.jiyuan'),
                // text:郑州
                target:  i18n.t('baseList.zhengzhou'),
              },
              {
                // text:三门峡
                source:  i18n.t('baseList.sanmenxia'),
                // text:郑州
                target: i18n.t('baseList.zhengzhou'),
              },
              {
                // text:商丘
                source:  i18n.t('baseList.shangqiu'),
                // text:郑州
                target:  i18n.t('baseList.zhengzhou'),
              },
              {
                // text:鹤壁
                source:  i18n.t('baseList.hebi'),
                // text:郑州
                target:  i18n.t('baseList.zhengzhou'),
              },
              {
                // text:濮阳
                source:  i18n.t('baseList.puyang'),
                // text:郑州
                target:  i18n.t('baseList.zhengzhou'),
              },
              {
                // text:安阳
                source:  i18n.t('baseList.anyang'),
                // text:郑州
                target:  i18n.t('baseList.zhengzhou'),
              },
              {
                // text:许昌
                source:  i18n.t('baseList.xuchang'),
                // text:南阳
                target:  i18n.t('baseList.nanyang'),
                color: '#37a2da',
              },
              {
                // text:平顶山
                source:  i18n.t('baseList.pingdingshan'),
                // text:南阳
                target:  i18n.t('baseList.nanyang'),
                color: '#37a2da',
              },
              {
                // text:洛阳
                source:  i18n.t('baseList.luoyang'),
                // text:南阳
                target: i18n.t('baseList.nanyang'),
                color: '#37a2da',
              },
              {
                // text:驻马店
                source: i18n.t('baseList.zhumadian'),
                // text:周口
                target: i18n.t('baseList.zhoukou'),
                color: '#8378ea',
              },
              {
                // text:信阳
                source: i18n.t('baseList.xinyang'),
                // text:周口
                target: i18n.t('baseList.zhoukou'),
                color: '#8378ea',
              },
              {
                // text:漯河
                source: i18n.t('baseList.luohe'),
                // text:周口
                target: i18n.t('baseList.zhoukou'),
                color: '#8378ea',
              },
            ],
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            echartFormatter:
              "(data)=>{\n  return {\n    config:{\n      points:data.points,\n      lines:data.lines,\n      icon: {\n        show: true,\n        src: '/img/flylineChart/mapPoint.png'\n      },\n      text: {\n        show: true,\n      },\n      k: 0.5,\n      bgImgSrc: '/img/flylineChart/map.jpg'\n    }\n  }\n}",
            component: {
              width: 949.32,
              height: 515.7,
              name: 'datav',
              prop: 'datav',
            },
            option: {
              is: 'dv-flyline-chart-enhanced',
            },
          },
        },
        // text:动态环图
        {
          label: i18n.t('baseList.dynamic_ring_chart'),
          option: {
            // text:动态环图
            name: i18n.t('baseList.dynamic_ring_chart'),
            title: 'datav',
            icon: 'icon-datav',
            img: '/img/assets/datav/datav5.png',
            dataType: 0,
            data: [
              {
                // text:周口
                name: i18n.t('baseList.zhoukou'),
                value: 55,
              },
              {
                // text:南阳
                name: i18n.t('baseList.nanyang'),
                value: 120,
              },
              {
                // text:西峡
                name: i18n.t('baseList.xixia'),
                value: 78,
              },
              {
                // text:驻马店
                name: i18n.t('baseList.zhumadian'),
                value: 66,
              },
              {
                // text:新乡
                name:i18n.t('baseList.xinxiang'),
                value: 80,
              },
            ],
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            echartFormatter: "(data)=>{\n  return {\n    config:{\n      radius: '40%',\n      activeRadius: '45%',\n      data: data,\n      digitalFlopStyle: {\n        fontSize: 20\n      },\n      showOriginValue: true\n    }\n  }\n}",
            component: {
              width: 949.32,
              height: 515.7,
              name: 'datav',
              prop: 'datav',
            },
            option: {
              is: 'dv-active-ring-chart',
            },
          },
        },
      ],
    },
    {
      // text:装饰
      label: i18n.t('baseList.decoration'),
      icon: 'icon-jianzhuzhuangshi',
      children: [
        {
          // text:装饰1
          label: i18n.t('baseList.decoration1'),
          option: {
            // text:装饰1
            title: i18n.t('baseList.decoration1'),
            name: 'datav',
            icon: 'icon-jianzhuzhuangshi',
            img: '/img/assets/decorate/decorate_01.gif',
            top: 0,
            left: 0,
            data: {},
            component: {
              width: 670,
              height: 370,
              name: 'decoration',
              prop: 'decoration',
            },
            option: {
              type: 1,
              color1: '#00CED1',
              color2: '#FAD400',
              dur: undefined,
            },
          },
        },
        {
          // text:装饰2
          label: i18n.t('baseList.decoration2'),
          option: {
            // text:装饰2
            title: i18n.t('baseList.decoration2'),
            name: 'datav',
            icon: 'icon-jianzhuzhuangshi',
            img: '/img/assets/decorate/decorate_02.gif',
            top: 0,
            left: 0,
            data: {},
            component: {
              width: 670,
              height: 370,
              name: 'decoration',
              prop: 'decoration',
            },
            option: {
              type: 2,
              color1: '#00CED1',
              color2: '#FAD400',
              dur: undefined,
            },
          },
        },
        {
          // text:装饰3
          label: i18n.t('baseList.decoration3'),
          option: {
            // text:装饰3
            title: i18n.t('baseList.decoration3'),
            name: 'datav',
            icon: 'icon-jianzhuzhuangshi',
            img: '/img/assets/decorate/decorate_03.gif',
            top: 0,
            left: 0,
            data: {},
            component: {
              width: 670,
              height: 370,
              name: 'decoration',
              prop: 'decoration',
            },
            option: {
              type: 3,
              color1: '#00CED1',
              color2: '#FAD400',
              dur: undefined,
            },
          },
        },
        {
          // text:装饰4
          label: i18n.t('baseList.decoration4'),
          option: {
            // text:装饰4
            title: i18n.t('baseList.decoration4'),
            name: 'datav',
            icon: 'icon-jianzhuzhuangshi',
            img: '/img/assets/decorate/decorate_04.gif',
            top: 0,
            left: 0,
            data: {},
            component: {
              width: 670,
              height: 370,
              name: 'decoration',
              prop: 'decoration',
            },
            option: {
              type: 4,
              color1: '#00CED1',
              color2: '#FAD400',
              dur: undefined,
            },
          },
        },
        {
          // text:装饰5
          label: i18n.t('baseList.decoration5'),
          option: {
            // text:装饰5
            title: i18n.t('baseList.decoration5'),
            name: 'datav',
            icon: 'icon-jianzhuzhuangshi',
            img: '/img/assets/decorate/decorate_05.png',
            top: 0,
            left: 0,
            data: {},
            component: {
              width: 670,
              height: 370,
              name: 'decoration',
              prop: 'decoration',
            },
            option: {
              type: 5,
              color1: '#00CED1',
              color2: '#FAD400',
              dur: undefined,
            },
          },
        },
        {
          // text:装饰6
          label: i18n.t('baseList.decoration6'),
          option: {
            // text:装饰6
            title: i18n.t('baseList.decoration6'),
            name: 'datav',
            icon: 'icon-jianzhuzhuangshi',
            img: '/img/assets/decorate/decorate_06.gif',
            top: 0,
            left: 0,
            data: {},
            component: {
              width: 670,
              height: 370,
              name: 'decoration',
              prop: 'decoration',
            },
            option: {
              type: 6,
              color1: '#00CED1',
              color2: '#FAD400',
              dur: undefined,
            },
          },
        },
        {
          // text:装饰7
          label: i18n.t('baseList.decoration7'),
          option: {
            // text:装饰7
            title: i18n.t('baseList.decoration7'),
            name: 'datav',
            icon: 'icon-jianzhuzhuangshi',
            img: '/img/assets/decorate/decorate_07.png',
            top: 0,
            left: 0,
            data: {},
            component: {
              width: 670,
              height: 370,
              name: 'decoration',
              prop: 'decoration',
            },
            option: {
              type: 7,
              color1: '#00CED1',
              color2: '#FAD400',
              dur: undefined,
            },
          },
        },
        {
          // text:装饰8
          label: i18n.t('baseList.decoration8'),
          option: {
            // text:装饰8
            title: i18n.t('baseList.decoration8'),
            name: 'datav',
            icon: 'icon-jianzhuzhuangshi',
            img: '/img/assets/decorate/decorate_08.png',
            top: 0,
            left: 0,
            data: {},
            component: {
              width: 670,
              height: 370,
              name: 'decoration',
              prop: 'decoration',
            },
            option: {
              type: 8,
              color1: '#00CED1',
              color2: '#FAD400',
              dur: undefined,
            },
          },
        },
        {
          // text:装饰9
          label: i18n.t('baseList.decoration9'),
          option: {
            // text:装饰9
            title: i18n.t('baseList.decoration9'),
            name: 'datav',
            icon: 'icon-jianzhuzhuangshi',
            img: '/img/assets/decorate/decorate_09.gif',
            top: 0,
            left: 0,
            data: {},
            component: {
              width: 440.5,
              height: 377.65,
              name: 'decoration',
              prop: 'decoration',
            },
            option: {
              type: 9,
              color1: '#00CED1',
              color2: '#FAD400',
              dur: undefined,
            },
          },
        },
        {
          // text:装饰10
          label: i18n.t('baseList.decoration10'),
          option: {
            // text:装饰10
            title: i18n.t('baseList.decoration10'),
            name: 'datav',
            icon: 'icon-jianzhuzhuangshi',
            img: '/img/assets/decorate/decorate_10.png',
            top: 0,
            left: 0,
            data: {},
            component: {
              width: 670,
              height: 370,
              name: 'decoration',
              prop: 'decoration',
            },
            option: {
              type: 10,
              color1: '#00CED1',
              color2: '#FAD400',
              dur: undefined,
            },
          },
        },
        {
          // text:装饰11
          label:i18n.t('baseList.decoration11'),
          option: {
            // text:装饰11
            title: i18n.t('baseList.decoration11'),
            name: 'datav',
            icon: 'icon-jianzhuzhuangshi',
            img: '/img/assets/decorate/decorate_11.png',
            top: 0,
            left: 0,
            data: {},
            component: {
              width: 670,
              height: 370,
              name: 'decoration',
              prop: 'decoration',
            },
            option: {
              type: 11,
              color1: '#00CED1',
              color2: '#FAD400',
              dur: undefined,
            },
          },
        },
        {
          // text:装饰12
          label: i18n.t('baseList.decoration12'),
          option: {
            // text:装饰12
            title: i18n.t('baseList.decoration12'),
            name: 'datav',
            icon: 'icon-jianzhuzhuangshi',
            img: '/img/assets/decorate/decorate_12.gif',
            top: 0,
            left: 0,
            data: {},
            component: {
              width: 430,
              height: 430,
              name: 'decoration',
              prop: 'decoration',
            },
            option: {
              type: 12,
              color1: '#00CED1',
              color2: '#FAD400',
              dur: undefined,
            },
          },
        },
      ],
    },
    {
      // text:二次开发
      label: i18n.t('baseList.secondary_development'),
      icon: 'el-icon-info',
      children: [
        {
          // text:自定义组件
          label: i18n.t('baseList.custom_component'),
          option: {
            // text:自定义组件
            name: i18n.t('baseList.custom_component'),
            // text:自定义组件
            title: i18n.t('baseList.custom_component'),
            ico: 'icon-bar',
            img: '/img/assets/text4.png',
            dataType: 0,
            data: [],
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            component: {
              width: 800,
              height: 500,
              name: 'test',
              prop: 'test',
            },
            option: {
              fontSize: 20,
            },
          },
        },
        {
          // text:滚动选项卡
          label: i18n.t('baseList.scroll_tab'),
          option: {
            // text:滚动选项卡
            name: i18n.t('baseList.scroll_tab'),
            // text:滚动选项卡
            title: i18n.t('baseList.scroll_tab'),
            icon: 'icon-bar',
            img: '/img/assets/text4.png',
            dataType: 0,
            data: [
              {
                // text:领导调研
                text: i18n.t('baseList.leadership_research'),
                list: ['https://img.alicdn.com/tfs/TB1v28TC8v0gK0jSZKbXXbK2FXa-1880-640.jpg', 'https://img.alicdn.com/tfs/TB1uevcCrj1gK0jSZFuXXcrHpXa-1880-640.jpg'],
              },
              {
                // text:领导调研
                text: i18n.t('baseList.leadership_research'),
                list: ['https://img.alicdn.com/imgextra/i1/O1CN01NAnI6W1Vf1kZScscd_!!6000000002679-0-tps-2880-1070.jpg', 'https://img.alicdn.com/imgextra/i2/O1CN011FMQT21RF8u1nHsem_!!6000000002081-0-tps-2880-1070.jpg'],
              },
            ],
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            component: {
              width: 800,
              height: 400,
              name: 'imgTabs',
              prop: 'imgTabs',
            },
            option: {
              direction: 'horizontal',
              interval: 3000,
              time: 3000,
              autoplay: true,
            },
          },
        },
        {
          // text:滚动列表
          label: i18n.t('baseList.scrolling_list'),
          option: {
            // text:滚动列表
            name: i18n.t('baseList.scrolling_list'),
            // text:滚动列表
            title: i18n.t('baseList.scrolling_list'),
            icon: 'icon-bar',
            img: '/img/assets/text4.png',
            dataType: 0,
            data: [
              {
                name: 'smallwei',
                time: '11:26',
                color: '#7c324c',
                date: '2022-05-22',
                // text:设计部
                dept: i18n.t('baseList.design_department'),
                img: 'https://oss.bladex.vip/caster/upload/20220522/6f2a92d95b64efeb42c131489abaff2f.webp',
              },
              {
                name: 'smallwei',
                time: '11:26',
                color: '#6b6230',
                date: '2022-05-22',
                // text:设计部
                dept: i18n.t('baseList.design_department'),
                img: 'https://oss.bladex.vip/caster/upload/20220522/6f2a92d95b64efeb42c131489abaff2f.webp',
              },
              {
                name: 'smallwei',
                time: '11:26',
                date: '2022-05-22',
                color: '#274d86',
                // text:设计部
                dept: i18n.t('baseList.design_department'),
                img: 'https://oss.bladex.vip/caster/upload/20220522/6f2a92d95b64efeb42c131489abaff2f.webp',
              },
            ],
            dataFormatter: '',
            dataHeader: '',
            dataQuery: '',
            stylesFormatter: '',
            component: {
              width: 800,
              height: 500,
              name: 'imgList',
              prop: 'imgList',
            },
            option: {
              step: 1,
              direction: 1,
              hoverStop: true,
            },
          },
        },
      ],
    },
  ],
};
