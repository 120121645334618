export default {
  optionSwiper: {
    type: '类型',
    carouselTime: '轮播时间',
    selector: '选择器',
    imageOpacity: '图片透明度',
    autoPlayVideo: '视频自动播放',
    videoControls: '视频控制台',
    loopVideo: '视频循环',
  },
};
