export default {
  'optionBar': {
    marquee: 'Marquee',
    verticalDisplay: 'Vertical Display',
    columnWidth: 'Column Width',
    maxWidth: 'Max Width',
    cornerRadius: 'Corner Radius',
    minHeight: 'Min Height',
  },
};
