export default {
  baseList: {
    charts: 'Charts',
    common: 'Common',
    apple: 'Apple',
    samsung: 'Samsung',
    xiaomi: 'Xiaomi',
    mobile_phone_brands: 'Mobile Phone Brands',
    bar_chart: 'Bar Chart',
    line_chart: 'Line Chart',
    pie_chart: 'Pie Chart',
    dji: 'DJI',
    tiktok: 'TikTok',
    radar_chart: 'Radar Chart',
    sales: 'Sales',
    management: 'Management',
    information_technology: 'Information Technology',
    customer_service: 'Customer Service',
    research_and_development: 'Research and Development',
    marketing: 'Marketing',
    scatter_plot: 'Scatter Plot',
    funnel_chart: 'Funnel Chart',
    map: 'Map',
    coordinate_1: 'Coordinate 1',
    coordinate_2: 'Coordinate 2',
    name: 'Name',
    pictograph: 'Pictograph',
    rectangle_chart: 'Rectangle Chart',
    Classification_1: 'Classification 1',
    Classification_2: 'Classification 2',
    Classification_3: 'Classification 3',
    Classification_4: 'Classification 4',
    Classification_5: 'Classification 5',
    Classification_6: 'Classification 6',
    Classification_7: 'Classification 7',
    Classification_8: 'Classification 8',
    Classification_9: 'Classification 9',
    Classification_10: 'Classification 10',
    Classification_11: 'Classification 11',
    Classification_12: 'Classification 12',
    Classification_13: 'Classification 13',
    Classification_14: 'Classification 14',
    Classification_15: 'Classification 15',
    Classification_16: 'Classification 16',
    Classification_17: 'Classification 17',
    Classification_18: 'Classification 18',
    Classification_19: 'Classification 19',
    Classification_20: 'Classification 20',
    timer: 'Timer',
    Custom_Vue_Component: 'Custom Vue Component',
    text: 'Text',
    text_box: 'Text Box',
    marquee: 'Marquee',
    hyperlink: 'Hyperlink',
    real_time_time: 'Real Time',
    media: 'Media',
    image: 'Image',
    image_box: 'Image Box',
    carousel: 'Carousel',

    video_player: 'Video Player',
    hls_player: 'HLS Player',
    indicator: 'Indicator',
    flip_card: 'Flip Card',
    color_block: 'Color Block',
    total_number_of_people: 'Total Number of People',
    person: 'Person',
    ring_chart: 'Ring Chart',
    number_of_people_increase: 'Number of People Increase',
    progress_bar: 'Progress Bar',
    gauge: 'Gauge',
    word_cloud: 'Word Cloud',
    Huawei: 'Huawei',
    Kuaishou: 'Kuaishou',
    Taobao: 'Taobao',
    Baidu: 'Baidu',
    JD: 'JD',
    Tmall: 'Tmall',
    character1: 'Character 1',

    table: 'Table',
    data1: 'Data 1',
    data2: 'Data 2',
    data3: 'Data 3',
    data4: 'Data 4',
    column1: 'Column 1',
    column2: 'Column 2',
    column3: 'Column 3',
    column4: 'Column 4',
    tab: 'Tab',
    tab1: 'Tab 1',
    tab2: 'Tab 2',
    border: 'Border',
    border1: 'Border 1',
    border2: 'Border 2',
    border3: 'Border 3',
    border4: 'Border 4',
    border5: 'Border 5',
    border6: 'Border 6',
    border7: 'Border 7',
    border8: 'Border 8',
    border9: 'Border 9',
    border10: 'Border 10',
    border11: 'Border 11',
    border12: 'Border 12',

    datav_example: 'Datav Example',
    scroll_ranking: 'Scroll Ranking',
    zhoukou: 'Zhoukou',
    nanyang: 'Nanyang',
    xixia: 'Xixia',
    zhumadian: 'Zhumadian',
    xinxiang: 'Xinxiang',
    xinyang: 'Xinyang',
    luohe: 'Luohe',
    capsule_ranking: 'Capsule Ranking',
    water_level_chart: 'Water Level Chart',
    progress_pool: 'Progress Pool',
    cone_column_chart: 'Cone Column Chart',
    flight_line_chart: 'Flight Line Chart',
    zhengzhou: 'Zhengzhou',
    jiaozuo: 'Jiaozuo',
    kaifeng: 'Kaifeng',
    xuchang: 'Xuchang',
    pingdingshan: 'Pingdingshan',
    luoyang: 'Luoyang',
    jiyuan: 'Jiyuan',
    sanmenxia: 'Sanmenxia',
    shangqiu: 'Shangqiu',
    hebi: 'Hebi',
    puyang: 'Puyang',
    anyang: 'Anyang',
    dynamic_ring_chart: 'Dynamic Ring Chart',
    decoration: 'Decoration',
    decoration1: 'Decoration 1',
    decoration2: 'Decoration 2',
    decoration3: 'Decoration 3',
    decoration4: 'Decoration 4',
    decoration5: 'Decoration 5',
    decoration6: 'Decoration 6',
    decoration7: 'Decoration 7',
    decoration8: 'Decoration 8',
    decoration9: 'Decoration 9',
    decoration10: 'Decoration 10',
    decoration11: 'Decoration 11',
    decoration12: 'Decoration 12',
    secondary_development: 'Secondary Development',
    custom_component: 'Custom Component',
    scroll_tab: 'Scrolling Tab',
    leadership_research: 'Leadership Research',
    scrolling_list: 'Scrolling List',
    design_department: 'Design Department',
  },
};
