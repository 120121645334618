export default {
  optionText: {
    textContent: '文本内容',
    fontType: '字体类型',
    fontSize: '字体大小',
    fontColor: '字体颜色',
    letterSpacing: '字体间距',
    lineHeight: '字体行高',
    fontBackground: '字体背景',
    fontWeight: '文字粗细',
    alignment: '对齐方式',
    marqueeSetting: '跑马灯设置',
    enableMarquee: '开启',
    scrollingSpeed: '滚动速度',
    hyperlinkSetting: '超链设置',
    enableHyperlink: '开启',
    openMode: '打开方式',
    hyperlinkURL: '超链地址',
  },
};
