export default {
  groupContainer: {
    loading: 'Loading, please wait',
    watermark_added: 'Watermark added. Only effective for viewing pages.',
    screen_not_published: 'The screen has not been published yet. Please come back later!',
    prompt: 'Prompt',
    please_enter_password: 'Please enter password',
    confirm: 'Confirm',
    incorrect_password: 'Incorrect password, please try again.',
    request_failed: 'Request failed',
  },
};
