import Vue from 'vue'
import Element from 'element-ui'
import VueI18n from 'vue-i18n'
import elementEnLocale from 'element-ui/lib/locale/lang/en'
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'
import ElementLocale from 'element-ui/lib/locale'
//路由
import enLocale from './lang/en-US'
import zhLocale from './lang/zh-CN'


Vue.use(VueI18n)

Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
})

const messages = {
  'en-US': {
    ...enLocale,
    ...elementEnLocale
  },
  'zh-CN': {
    ...zhLocale,
    ...elementZhLocale
  },
  
}
export function getLanguage() {
  const chooseLanguage = localStorage.getItem('locale')

  
  if (chooseLanguage) return chooseLanguage

  // 浏览器默认值
  const language = (navigator.language || navigator.browserLanguage)

  var locale = 'en-US'
  Object.keys(messages).forEach((key)=>{
    if (language.indexOf(key) != -1) locale = language
  })

  localStorage.setItem('locale', locale);
  return locale
}
const i18n = new VueI18n({
  locale: getLanguage(),
  messages
})


export default i18n
