import global from './zh-CN/global'
import basicList from './zh-CN/basicList'
import bar from './zh-CN/option/bar'
import borderBox from './zh-CN/option/borderBox'
import clapper from './zh-CN/option/clapper'
import common from './zh-CN/option/common'
import datav from './zh-CN/option/datav'
import datetime from './zh-CN/option/datetime'
import decoration from './zh-CN/option/decoration'
import flop from './zh-CN/option/flop'
import funnel from './zh-CN/option/funnel'
import gauge from './zh-CN/option/gauge'
import iframe from './zh-CN/option/iframe'
import img from './zh-CN/option/img'
import imgborder from './zh-CN/option/imgborder'
import line from './zh-CN/option/line'
import main from './zh-CN/option/main'
import map from './zh-CN/option/map'
import pictorialbar from './zh-CN/option/pictorialbar'
import progress from './zh-CN/option/progress'
import pie from './zh-CN/option/pie'
import radar from './zh-CN/option/radar'
import scatter from './zh-CN/option/scatter'
import table from './zh-CN/option/table'
import tabs from './zh-CN/option/tabs'
import text from './zh-CN/option/text'
import time from './zh-CN/option/time'
import video from './zh-CN/option/video'
import vue from './zh-CN/option/vue'
import swiper from './zh-CN/option/swiper'
import wordcloud from './zh-CN/option/wordcloud'
import config from './zh-CN/option/config'
import code from './zh-CN/group/code'
import container from './zh-CN/group/container'
import contentmenu from './zh-CN/group/contentmenu'
import header from './zh-CN/group/header'
import imglist from './zh-CN/group/imglist'
import keys from './zh-CN/group/keys'
import result from './zh-CN/group/result'
import share from './zh-CN/group/share'


export default {
    ...global,
    ...basicList,
    ...bar,
    ...borderBox,
    ...clapper,
    ...common,
    ...datav,
    ...datetime,
    ...decoration,
    ...flop,
    ...funnel,
    ...gauge,
    ...iframe,
    ...img,
    ...imgborder,
    ...line,
    ...main,
    ...map,
    ...pictorialbar,
    ...progress,
    ...pie,
    ...radar,
    ...scatter,
    ...table,
    ...tabs,
    ...text,
    ...time,
    ...video,
    ...vue,
    ...wordcloud,
    ...config,
    ...code,
    ...container,
    ...contentmenu,
    ...header,
    ...imglist,
    ...keys,
    ...result,
    ...share,
    ...swiper,
}
