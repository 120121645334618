export default {
  optionFlop: {
    overall: 'Overall',
    precision: 'Precision',
    width: 'Width',
    margin: 'Margin',
    padding: 'Padding',
    border: 'Border',
    borderColor: 'Border Color',
    borderWidth: 'Border Width',
    imageURL: 'Image URL',
    backgroundColor: 'Background Color',
    contentSetting: 'Content Setting',
    content: 'Content',
    fontSize: 'Font Size',
    fontColor: 'Font Color',
    fontWeight: 'Font Weight',
    alignment: 'Alignment',
    prefixSetting: 'Prefix Setting',
    noLineBreak: 'No Line Break',
    prefixContent: 'Prefix Content',
    prefixAlignment: 'Alignment',
    prefixColor: 'Prefix Color',
    prefixFontSize: 'Prefix Font Size',
    prefixLineHeight: 'Prefix Line Height',
    suffixSetting: 'Suffix Setting',
    suffixContent: 'Suffix Content',
    suffixAlignment: 'Alignment',
    suffixColor: 'Suffix Color',
    suffixFontSize: 'Suffix Font Size',
    suffixLineHeight: 'Suffix Line Height',
  },
};
