import Cookies from 'js-cookie';
import { getLanguage } from '@/locales/index';

const state = {
  language: getLanguage(),
  user: {
    "authorities": [],
  },

};

const mutations = {
  setState: (state, obj) => {
    Object.keys(obj).forEach((key) => state[key] = obj[key])
  },

  SET_LANGUAGE: (state, language) => {
    state.language = language;
    Cookies.set('language', language);
  },
};

const actions = {
  setLanguage({ commit }, language) {
    commit('SET_LANGUAGE', language);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
