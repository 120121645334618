export default {
  optionFlop: {
    overall: '整体',
    precision: '精度',
    width: '长宽',
    margin: '外边距',
    padding: '内边距',
    border: '边框',
    borderColor: '边框颜色',
    borderWidth: '边框宽度',
    imageURL: '图片地址',
    backgroundColor: '背景颜色',
    contentSetting: '内容设置',
    content: '内容',
    fontSize: '字体大小',
    fontColor: '字体颜色',
    fontWeight: '文字粗细',
    alignment: '对齐方式',
    prefixSetting: '前缀设置',
    noLineBreak: '不换行',
    prefixContent: '前缀内容',
    prefixAlignment: '对齐方式',
    prefixColor: '颜色',
    prefixFontSize: '字体大小',
    prefixLineHeight: '字体行高',
    suffixSetting: '后缀设置',
    suffixContent: '后缀内容',
    suffixAlignment: '对齐方式',
    suffixColor: '颜色',
    suffixFontSize: '字体大小',
    suffixLineHeight: '字体行高',
  },
};
