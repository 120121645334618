export default {
  optionMain: {
    titleSetting:'标题设置',
    title: '标题',
    titleFontColor: '字体颜色',
    titleFontSize: '字体大小',
    titleFontPosition: '字体位置',
    subtitle: '副标题',
    subtitleFontColor: '字体颜色',
    subtitleFontSize: '字体大小',
    xAxisSetting: 'X轴设置',
    name: '名称',
    display: '显示',
    displayGridLines: '显示网格线',
    labelSpacing: '标签间距',
    textAngle: '文字角度',
    axisReversal: '轴反转',
    textSize: '文字大小',
    textColor: '文字颜色',
    axisLineColor: '轴线颜色',
    yAxisSetting: 'Y轴设置',
    axisGridLine: '轴网格线',
    reversal: '反转',
    valueSetting: '数值设置',
    fontSize: '字体大小',
    fontColor: '字体颜色',
    fontWeight: '字体粗细',
    tooltipSetting: '提示语设置',
    backgroundColor: '背景颜色',
    axisMarginSetting: '坐标轴边距设置',
    leftMargin: '左边距(像素)',
    topMargin: '顶边距(像素)',
    rightMargin: '右边距(像素)',
    bottomMargin: '底边距(像素)',
    legendOperation: '图例操作',
    legend: '图例',
    position: '位置',
    layoutOrientation: '布局朝向',
    customColor: '自定义配色',
  },
};
