export default {
  groupShare: {
    share: 'Share',
    view_screen: 'View Screen',
    copy_link: 'Copy Link',
    share_screen: 'Share Screen',
    screen_name: 'Screen Name',
    share_link: 'Share Link',
    share_password: 'Share Password',
    link_copy_success: 'Link Copy Success',
    screen_share_success: 'Screen Share Success',
  },
};
