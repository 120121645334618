export default {
  optionConfig: {
    color1: 'Color 1',
    gradientColor: 'Gradient color',
    position: 'Position',
    name: 'Name',
    keyValue: 'Key value',
    width: 'Width',
    status: 'Status',
    hidden: 'Hidden',
    displayed: 'Displayed',
    formatting: 'Formatting',
    line: 'Line',
    circle: 'Circle',
    noBorder: 'No border',
    builtInImage: 'Built-in image',
    builtInBorder: 'Built-in border',
    center: 'Center',
    leftAlignment: 'Left alignment',
    rightAlignment: 'Right alignment',
    staticData: 'Static data',
    apiData: 'API interface data',
    sqlDatabaseData: 'SQL database data',
    webSocketData: 'WebSocket',
    recordDataset: 'Record dataset',
    vertical: 'Vertical',
    horizontal: 'Horizontal',
    tab: 'Tab',
    checkbox: 'Checkbox',
    native: 'Native',
    sameWindow: 'Same window',
    newWindow: 'New window',
    normal: 'Normal',
    stereoscopic:'stereoscopic',
    external:'external',
    hide: 'Not displayed',
    date: 'Date',
    dateAndTime: 'Date + hour and minute',
    dateAndTimeWithSeconds: 'Date + hour, minute, and second',
    dateNoYear: 'Date (no year)',
    time: 'Hour and minute',
    timeWithSeconds: 'Hour, minute, and second',
    weekday: 'Weekday',
    simSun: 'SimSun',
    nSimSun: 'NSimSun',
    simHei: 'SimHei',
    kaiTi: 'KaiTi',
    microsoftYaHei: 'Microsoft YaHei',
    youYuan: 'YouYuan',
    stXihei: 'STXihei',
    ming: 'Ming',
    newMing: 'New Ming',
  },
};
