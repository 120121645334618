export default {
  groupResult: {
    import_export: '导入导出',
    import_component: '导入组件',
    import_data: '导入数据',
    export_data: '导出数据',
    import_component_success: '导入组件成功',
    import_component_error: '导入组件错误',
    import_data_success: '数据导入成功',
    import_data_error: '导入数据错误',
  },
};
