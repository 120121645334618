export default {
  groupKeys: {
    shortcut_key: '快捷键',
    function: '功能',
    move_up: '向上移动',
    move_right: '向右移动',
    move_down: '向下移动',
    move_left: '向左移动',
    long_press_to_move_in_one_direction: '长按向一个方向移动',
    delete_component: '删除组件',
    copy: '复制',
    paste: '粘贴',
    undo: '回退',
    redo: '前进',
    lock_unlock: '锁定/解锁',
    save_screen: '保存大屏',
    zoom_in_out_layout: '放大/缩小布局',
    select_all_with_clicking_folder_icon: '单击文件夹图标可以全选',
    modify_text_by_double_clicking_folder_name: '双击文件夹名称可以修改文字',
  },
};
