export default {
  optionGauge: {
    scaleValue: '刻度值',
    scaleFontSize: '刻度字号',
    scaleFontWeight: '圆环宽度',
    scaleColor: '刻度颜色',
    indicatorFontSize: '指标字体大小',
    indicatorUnit: '指标单位',
  },
};
