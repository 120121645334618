export default {
  'optionBorderBox': {
    decorationType: 'Decoration Type',
    primaryColor: 'Primary Color',
    secondaryColor: 'Secondary Color',
    backgroundColor: 'Background Color',
    animationDuration: 'Animation Duration',
    border: 'Border',
    marquee: 'Marquee',
  },
};
