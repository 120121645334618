import Router from 'vue-router';
import Vue from 'vue';
import getters from './store/getters';
import store from './store';
Vue.use(Router);
const vueRouter = new Router({
  base: process.env.VUE_APP_PATH,
  mode: process.env.VUE_APP_MODE,
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "page" */ '@/layouts/menuLayout'),
      children: [
        // 没权限
        {
          path: '/noAuthorty',
          component: () => import(/* webpackChunkName: "page" */ '@/page/noAuthorty'),
        },
        // 大屏管理
        {
          path: '',
          component: () => import(/* webpackChunkName: "page" */ '@/page/home'),
          meta: {
            isNeedLogin: true,
            authoritie: 'RESOURCE_SCREENMANAGEMENT',
          },
        },

        // 地图管理
        {
          path: 'map',
          component: () => import(/* webpackChunkName: "page" */ '@/page/map'),
          meta: {
            isNeedLogin: true,
            authoritie: 'RESOURCE_MAPMANAGEMENT'
          },
        },

        // 分类管理
        {
          path: 'category',
          component: () => import(/* webpackChunkName: "page" */ '@/page/category'),
          meta: {
            isNeedLogin: true,
          },
        },

        // 数据源管理
        {
          path: 'db',
          component: () => import(/* webpackChunkName: "page" */ '@/page/db'),
          meta: {
            isNeedLogin: true,
          },
        },

        // 组件库
        {
          path: 'components',
          component: () => import(/* webpackChunkName: "page" */ '@/page/compLibrary'),
          meta: {
            isNeedLogin: true,
          },
        },

        // 综合功能
        {
          path: 'document',
          component: () => import(/* webpackChunkName: "page" */ '@/page/document'),
          meta: {
            isNeedLogin: true,
          },
        },

        // 数据集
        {
          path: 'record',
          component: () => import(/* webpackChunkName: "page" */ '@/page/record'),
          meta: {
            isNeedLogin: true,
          },
        },

        // 用户管理
        {
          path: 'userControl',
          component: () => import(/* webpackChunkName: "page" */ '@/page/userControl'),
          meta: {
            isNeedLogin: true,
          },
        },

        // 开放管理
        {
          path: 'openPlatform',
          component: () => import(/* webpackChunkName: "page" */ '@/page/openPlatform'),
          meta: {
            isNeedLogin: true,
          },
        },

        // 新建大屏
        {
          path: '/create',
          name: 'create',
          component: () => import(/* webpackChunkName: "page" */ '@/page/create'),
          meta: {
            isNeedLogin: true,
          },
        },

        // 旧版首页
        {
          path: '/oldHome',
          component: () => import(/* webpackChunkName: "page" */ '@/page/oldHome'),
        },
      ],
    },

    // 编辑页面
    {
      path: '/build/:id',
      name: 'build',
      component: () => import(/* webpackChunkName: "page" */ '@/page/build'),
      meta: {
        isNeedLogin: true,

      },
    },

    // 预览页面
    {
      path: '/view/:id',
      name: 'view',
      component: () => import(/* webpackChunkName: "page" */ '@/page/view'),
      meta: {
        isNeedLogin: true,
      },
    },
    // 开放平台 预览页面
    {
      path: '/preview/:id',
      name: 'preView',
      component: () => import(/* webpackChunkName: "page" */ '@/page/preView'),
      meta: {
        isNeedLogin: false,
      },

    },

    // 登录
    {
      path: '/login',
      component: () => import(/* webpackChunkName: "page" */ '@/page/login'),
    },
    // 演示登录
    {
      path: '/demoLogin',
      component: () => import(/* webpackChunkName: "page" */ '@/page/demoLogin'),
    },

  ],
});

vueRouter.beforeEach((to, from, next) => {
  const userAuths = store.state.app.user.authorities
  const { id } = store.state.app.user

  // console.log(store, 'sssssssssssss');
  // 1获取路由的元数据信息
  let { isNeedLogin, authoritie } = to.meta;
  // 取出登录成功之后保存的登录凭证
  let token = window.localStorage.getItem('screen_token');
  // 2判断哪些页面需要进行登录判断
  if (isNeedLogin) {
    // 证明页面需要进行登录
    if (!token) {
      // 证明没有登录 去登录页面 next("/路由地址") 跳转功能
      // to.fullPath 跳转的目标路由地址  /cart
      let toPath = '/'
      if (to.fullPath) toPath = to.fullPath;
      next(`/login?toPath=${toPath}`);
    } else {
      // 证明登陆过了
      next();
    }
  }

  // 判断页面是否有权限
  if (id && authoritie) {
    if (userAuths.indexOf(authoritie) == -1) {
      next(`/noAuthorty`);
    }
  }
  next(); //继续跳转到对应的页面
});

export default vueRouter;
