export default {
  optionTabs: {
    fontSize: 'Font Size',
    fontColor: 'Font Color',
    type: 'Type',
    letterSpacing: 'Letter Spacing',
    borderSetting: 'Border Setting',
    backgroundColor: 'Background Color',
    iconSpacing: 'Icon Spacing',
    iconSize: 'Icon Size',
    imageURL: 'Image URL',
    borderColor: 'Border Color',
    borderWidth: 'Border Width',
    highlightSetting: 'Highlight Setting',
    highlightFontColor: 'Highlight Font Color',
    backgroundImage: 'Background Image',
  },
};
