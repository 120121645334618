export default {
  optionConfig: {
    color1: '颜色1',
    gradientColor: '渐变色',
    position: '位置',
    name: '名称',
    keyValue: 'key值',
    width: '宽度',
    status: '状态',
    hidden: '隐藏',
    displayed: '显示',
    formatting: '格式化',
    line: '线条',
    circle: '圆环',
    noBorder: '无边框',
    builtInImage: '内置图片',
    builtInBorder: '内置边框',
    center: '居中',
    leftAlignment: '左对齐',
    rightAlignment: '右对齐',
    staticData: '静态数据',
    apiData: 'API接口数据',
    sqlDatabaseData: 'SQL数据库数据',
    recordDataset: 'Record数据集',
    vertical: '竖排',
    horizontal: '横排',
    tab: '选项卡',
    checkbox: '选择框',
    native: '原生',
    sameWindow: '本窗口',
    newWindow: '新窗口',
    normal: '普通',
    stereoscopic:'立体',
    external:'外部',
    hide: '不显示',
    date: '日期',
    dateAndTime: '日期+时分',
    dateAndTimeWithSeconds: '日期+时分秒',
    dateNoYear: '日期(无年)',
    time: '时分',
    timeWithSeconds: '时分秒',
    weekday: '星期',
    simSun: '宋体',
    nSimSun: '新宋体',
    simHei: '黑体',
    kaiTi: '楷体',
    microsoftYaHei: '微软雅黑',
    youYuan: '幼圆',
    stXihei: '华文细黑',
    ming: '细明体',
    newMing: '新细明体',
  },
};
