export default {
  optionTable: {
    enableRanking: '开启排名',
    border: '边框',
    appendMode: '追加模式',
    enableScrolling: '开启滚动',
    scrollingInterval: '滚动间隔',
    scrollingSpeed: '滚动速度',
    tableHeaderSetting: '表头设置',
    display: '显示',
    fontSize: '字体大小',
    backgroundColor: '背景颜色',
    fontColor: '字体颜色',
    alignment: '对其方式',
    tableSetting: '表格设置',
    displayRows: '显示行数',
    textColor: '文字颜色',
    oddRowColor: '奇行颜色',
    evenRowColor: '偶行颜色',
    tableColumnSetting: '表格列设置',
    edit: '编辑',
    editData: '编辑数据',
  },
};
