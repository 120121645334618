export default {
  optionDatetime: {
    dateFormat: 'Date Format',
    customFormat: 'Custom Format',
    fontSpacing: 'Font Spacing',
    fontSize: 'Font Size',
    fontBackground: 'Font Background',
    alignment: 'Alignment',
    fontWeight: 'Font Weight',
    fontColor: 'Font Color',
  },
};
