export default {
  groupCode: {
    data_processing:'Data Processing',
    execution_logic: 'Execution Logic',
    click_event: 'Click Event',
    cancel: 'Cancel',
    confirm: 'Confirm',
    copy_success: 'Copy Success',
    copy_failure: 'Copy Failure',
    data_format_error: 'Data Format Error',
  },
};
