export default {
  optionTable: {
    enableRanking: 'Enable Ranking',
    border: 'Border',
    appendMode: 'Append Mode',
    enableScrolling: 'Enable Scrolling',
    scrollingInterval: 'Scrolling Interval',
    scrollingSpeed: 'Scrolling Speed',
    tableHeaderSetting: 'Table Header Setting',
    display: 'Display',
    fontSize: 'Font Size',
    backgroundColor: 'Background Color',
    fontColor: 'Font Color',
    alignment: 'Alignment',
    tableSetting: 'Table Setting',
    displayRows: 'Display Rows',
    textColor: 'Text Color',
    oddRowColor: 'Odd Row Color',
    evenRowColor: 'Even Row Color',
    tableColumnSetting: 'Table Column Setting',
    edit: 'Edit',
    editData: 'Edit Data',
  },
};
