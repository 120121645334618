export default {
  groupContentmenu: {
    unlock: '解锁',
    lock: '锁定',
    show: '显示',
    hide: '隐藏',
    dissolve: '解散',
    combine: '组合',
    delete: '删除',
    copy: '复制',
    bring_to_front: '置顶',
    send_to_back: '置底',
    move_up: '上移',
    move_down: '下移',
    group_selection: '是否组合所选择的图层',
    prompt: '提示',
    confirm: '确定',
    cancel: '取消',
    dissolve_selection: '是否解散',
    layer: '图层',
    delete_selection: '是否删除所选图层',
  },
};
