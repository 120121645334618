export default {
  groupHeader: {
    layer: '图层',
    operation: '操作',
    save: '保存',
    shortcut_key: '快捷键',
    export_picture:'导出图片',
    preview:'预览',
    restore: '还原',
    import_export: '导入导出',
    share: '分享',
    undo: '撤销',
    redo: '重做',
    home: '主页',
    saving_configuration: '正在保存配置，请稍后',
    save_configuration_success: '大屏配置保存成功',
    save_configuration_failure: '大屏配置保存失败，请检查服务端配置',
    export_screen_image: '是否导出大屏图片？',
    prompt: '提示',
    confirm: '确定',
    cancel: '取消',
    image_export_success: '图片导出成功',
  },
};
