export default {
  optionLine: {
    lineSetting: '折线设置',
    smoothCurve: '平滑曲线',
    stackedArea: '面积堆积',
    lineWidth: '线条宽度',
    dot: '圆点',
    dotSize: '点的大小',
  },
};
