export default {
  groupResult: {
    import_export: 'Import/Export',
    import_component: 'Import Component',
    import_data: 'Import Data',
    export_data: 'Export Data',
    import_component_success: 'Import Component Success',
    import_component_error: 'Import Component Error',
    import_data_success: 'Data Import Success',
    import_data_error: 'Import Data Error',
  },
};
