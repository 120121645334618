export default {
  optionMap: {
    mapSelection: 'Map Selection',
    pleaseSelectMap: 'Please Select Map',
    mapScale: 'Map Scale',
    mapZoom: 'Map Zoom',
    carouselSetting: 'Carousel Setting',
    enableCarousel: 'Enable Carousel',
    carouselTime: 'Carousel Time',
    basicSetting: 'Basic Setting',
    fontSize: 'Font Size',
    fontHighlightColor: 'Font Highlight Color',
    fontColor: 'Font Color',
    borderColor: 'Border Color',
    areaSetting: 'Area Setting',
    areaLine: 'Area Line',
    areaColor: 'Area Color',
    areaHighlightColor: 'Area Highlight Color',
    tooltipSetting: 'Tooltip Setting',
    backgroundColor: 'Background Color',
    textColor: 'Text Color',
    textSize: 'Text Size',
  },
};
