export default {
  groupContainer: {
    loading: '正在加载中，请稍后',
    watermark_added: '添加水印。只有查看页面生效',
    screen_not_published: '大屏还没有发布，晚一点再来吧！',
    prompt: '提示',
    please_enter_password: '请输入密码',
    confirm: '确定',
    incorrect_password: '密码不正确，请重新输入',
    request_failed: '请求失败',
  },
};
