export default {
  optionSwiper: {
    type: 'Type',
    carouselTime: 'Carousel Time',
    selector: 'Selector',
    imageOpacity: 'Image Opacity',
    autoPlayVideo: 'Auto Play Video',
    videoControls: 'Video Controls',
    loopVideo: 'Loop Video',
  },
};
