export default {
  groupKeys: {
    shortcut_key: 'Shortcut Key',
    function: 'Function',
    move_up: 'Move Up',
    move_right: 'Move Right',
    move_down: 'Move Down',
    move_left: 'Move Left',
    long_press_to_move_in_one_direction: 'Long press to move in one direction',
    delete_component: 'Delete Component',
    copy: 'Copy',
    paste: 'Paste',
    undo: 'Undo',
    redo: 'Redo',
    lock_unlock: 'Lock/Unlock',
    save_screen: 'Save Screen',
    zoom_in_out_layout: 'Zoom In/Out Layout',
    select_all_with_clicking_folder_icon: 'Select All with Clicking Folder Icon',
    modify_text_by_double_clicking_folder_name: 'Modify Text by Double Clicking Folder Name',
  },
};
