export default {
  optionMain: {
    titleSetting:'Title Setting',
    title: 'Title',
    titleFontColor: 'Title Font Color',
    titleFontSize: 'Title Font Size',
    titleFontPosition: 'Title Font Position',
    subtitle: 'Subtitle',
    subtitleFontColor: 'Subtitle Font Color',
    subtitleFontSize: 'Subtitle Font Size',
    xAxisSetting: 'X-Axis Setting',
    name: 'Name',
    display: 'Display',
    displayGridLines: 'Display Grid Lines',
    labelSpacing: 'Label Spacing',
    textAngle: 'Text Angle',
    axisReversal: 'Axis Reversal',
    textSize: 'Text Size',
    textColor: 'Text Color',
    axisLineColor: 'Axis Line Color',
    yAxisSetting: 'Y-Axis Setting',
    axisGridLine: 'Axis Grid Line',
    reversal: 'Reversal',
    valueSetting: 'Value Setting',
    fontSize: 'Font Size',
    fontColor: 'Font Color',
    fontWeight: 'Font Weight',
    tooltipSetting: 'Tooltip Setting',
    backgroundColor: 'Background Color',
    axisMarginSetting: 'Axis Margin Setting',
    leftMargin: 'Left Margin (Pixels)',
    topMargin: 'Top Margin (Pixels)',
    rightMargin: 'Right Margin (Pixels)',
    bottomMargin: 'Bottom Margin (Pixels)',
    legendOperation: 'Legend Operation',
    legend: 'Legend',
    position: 'Position',
    layoutOrientation: 'Layout Orientation',
    customColor: 'Custom Color'
}
};
