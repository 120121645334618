export default {
  navs: {
    dashboard: '大屏管理',
    mapManagement: '地图管理',
    category: '分类管理',
    db: '数据源管理',
    record: '数据集管理',
    Component: '组件库',
    userControl: '用户管理',
    openPlatform: '开放平台',
  },

  layout: {
    group: '我的分组',
  },

  crud: {
    editBtnText: '编辑',
    delBtnText: '删除',
    add: '新增',
    update: '修改',
    cancel: '取消',
    saveBtnText: '保存',
    copyBtnText:'复制',
    action:'操作'
  },
};
