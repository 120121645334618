export default {
  navs: {
    dashboard: 'Home',
    mapManagement: 'Set Up Map',
    category: 'Setting Classificati',
    db: 'Data Management',
    record: 'Data Set',
    Component: 'Component Gallery',
    userControl: 'Users',
    openPlatform: 'Open Site',
  },

  layout: {
    group: 'My Group',
  },

  crud: {
    editBtnText: 'Edit',
    delBtnText: 'Delete',
    add: 'Add',
    update: 'Update',
    cancel: 'Cancel',
    saveBtnText: 'Save',
    copyBtnText:'Copy',
    action:'Action'
  },
};
