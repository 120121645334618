export default {
  optionProgress: {
    type: 'Type',
    width: 'Width',
    color: 'Color',
    backgroundColor: 'Background Color',
    fontSize: 'Font Size',
    fontColor: 'Font Color',
    fontWeight: 'Font Weight',
    prefixFontSize: 'Prefix Font Size',
    prefixFontColor: 'Prefix Font Color',
    prefixFontWeight: 'Prefix Font Weight',
  },
};
