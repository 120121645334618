export default {
  'optionBorderBox': {
    decorationType: '装饰类型',
    primaryColor: '主颜色',
    secondaryColor: '副颜色',
    backgroundColor: '背景色',
    animationDuration: '动画时长',
    border: '边框',
  },
};
