import i18n from './locales'
import './init'
import Vue from 'vue'
import ElementUI from 'element-ui';
import axios from './axios'
import 'element-ui/lib/theme-chalk/index.css';
import dataV from '@jiaminghi/data-view'
import router from './router.js';
import { website } from '@/config.js'
import draggable from '@/page/components/draggable'
import { loadScript, conversionTime } from '@/utils/utils'
import VueClipBoard from 'vue-clipboard2'
import App from './App.vue'
import store from './store'
import { authorityVerify } from '@/utils/utils'

import './styles/common.scss'
import '@/utils/es6'

//导入主题文件
import '@/theme/index.js'
Vue.config.productionTip = false
window.axios = axios;
window.$loadScript = loadScript;
document.title = website.title
Vue.use(ElementUI);
Vue.use(window.AVUE);
Vue.use(VueClipBoard)
Vue.component('avue-draggable', draggable)
Vue.prototype.$website = website;
Vue.prototype.authorityVerify = authorityVerify
Vue.filter('conversionTime', conversionTime);
Vue.use(dataV)
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
