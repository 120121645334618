export default {
  groupCode: {
    data_processing:'数据处理',
    execution_logic: '执行逻辑',
    click_event: '点击事件',
    cancel: '取消',
    confirm: '确定',
    copy_success: '复制成功',
    copy_failure: '复制失败',
    data_format_error: '数据格式有误',
  },
};
