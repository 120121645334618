export default {
  optionGauge: {
    scaleValue: 'Scale Value',
    scaleFontSize: 'Scale Font Size',
    scaleFontWeight: 'Ring Width',
    scaleColor: 'Scale Color',
    indicatorFontSize: 'Indicator Font Size',
    indicatorUnit: 'Indicator Unit',
  },
};
