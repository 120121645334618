export default {
  baseList: {
    charts: '图表',
    common: '通用型',
    apple: '苹果',
    samsung: '三星',
    xiaomi: '小米',
    mobile_phone_brands: '手机品牌',
    bar_chart: '柱形图',
    line_chart: '折线图',
    pie_chart: '饼图',
    dji: '大疆',
    tiktok: '抖音',
    radar_chart: '雷达图',
    sales: '销售',
    management: '管理',
    information_technology: '信息技术',
    customer_service: '客服',
    research_and_development: '研发',
    marketing: '市场',
    scatter_plot: '散点图',
    funnel_chart: '漏斗图',
    map: '地图',
    coordinate_1: '测试坐标1',
    coordinate_2: '测试坐标2',
    name: '名称',
    pictograph:'象形图',
    rectangle_chart: '矩形图',
    Classification_1: '分类 1',
    Classification_2: '分类 2',
    Classification_3: '分类 3',
    Classification_4: '分类 4',
    Classification_5: '分类 5',
    Classification_6: '分类 6',
    Classification_7: '分类 7',
    Classification_8: '分类 8',
    Classification_9: '分类 9',
    Classification_10: '分类 10',
    Classification_11: '分类 11',
    Classification_12: '分类 12',
    Classification_13: '分类 13',
    Classification_14: '分类 14',
    Classification_15: '分类 15',
    Classification_16: '分类 16',
    Classification_17: '分类 17',
    Classification_18: '分类 18',
    Classification_19: '分类 19',
    Classification_20: '分类 20',
    timer: '定时器',
    Custom_Vue_Component: '自定义Vue组件',
    text: '文字',
    text_box: '文本框',
    marquee: '跑马灯',
    hyperlink: '超链接',
    real_time_time: '实时时间',
    media: '媒体',
    image: '图片',
    image_box: '图片框',
    carousel: '轮播图',

    video_player: 'video播放器',
    hls_player: 'hls播放器',
    indicator: '指标',
    flip_card: '翻牌器',
    color_block: '颜色块',
    total_number_of_people: '某某某总人数',
    person: '人',
    ring_chart: '环形图',
    number_of_people_increase: '人数增涨',
    progress_bar: '进度条',
    gauge: '仪表盘',
    word_cloud: '字符云',
    Huawei: '华为',
    Kuaishou: '快手',
    Taobao: '淘宝',
    Baidu: '百度',
    JD: '京东',
    Tmall: '天猫',
    character1: '字符1',

    table: '表格',
    data1: '数据1',
    data2: '数据2',
    data3: '数据3',
    data4: '数据4',
    column1: '列1',
    column2: '列2',
    column3: '列3',
    column4: '列4',
    tab: '选项卡',
    tab1: '选项卡1',
    tab2: '选项卡2',
    border: '边框',
    border1: '边框1',
    border2: '边框2',
    border3: '边框3',
    border4: '边框4',
    border5: '边框5',
    border6: '边框6',
    border7: '边框7',
    border8: '边框8',
    border9: '边框9',
    border10: '边框10',
    border11: '边框11',
    border12: '边框12',

    datav_example: 'datav例子',
    scroll_ranking: '滚动排名',
    zhoukou: '周口',
    nanyang: '南阳',
    xixia: '西峡',
    zhumadian: '驻马店',
    xinxiang: '新乡',
    xinyang: '信阳',
    luohe: '漯河',
    capsule_ranking: '胶囊排名',
    water_level_chart: '水位图',
    progress_pool: '进度池',
    cone_column_chart: '锥形柱图',
    flight_line_chart: '飞线图',
    zhengzhou: '郑州',
    jiaozuo: '焦作',
    kaifeng: '开封',
    xuchang: '许昌',
    pingdingshan: '平顶山',
    luoyang: '洛阳',
    jiyuan: '济源',
    sanmenxia: '三门峡',
    shangqiu: '商丘',
    hebi: '鹤壁',
    puyang: '濮阳',
    anyang: '安阳',
    dynamic_ring_chart: '动态环图',
    decoration: '装饰',
    decoration1: '装饰1',
    decoration2: '装饰2',
    decoration3: '装饰3',
    decoration4: '装饰4',
    decoration5: '装饰5',
    decoration6: '装饰6',
    decoration7: '装饰7',
    decoration8: '装饰8',
    decoration9: '装饰9',
    decoration10: '装饰10',
    decoration11: '装饰11',
    decoration12: '装饰12',
    secondary_development: '二次开发',
    custom_component: '自定义组件',
    scroll_tab: '滚动选项卡',
    leadership_research: '领导调研',
    scrolling_list: '滚动列表',
    design_department: '设计部',
  },
};
