import global from './en-US/global'
import basicList from './en-US/basicList'
import bar from './en-US/option/bar'
import borderBox from './en-US/option/borderBox'
import clapper from './en-US/option/clapper'
import common from './en-US/option/common'
import datav from './en-US/option/datav'
import datetime from './en-US/option/datetime'
import decoration from './en-US/option/decoration'
import flop from './en-US/option/flop'
import funnel from './en-US/option/funnel'
import gauge from './en-US/option/gauge'
import iframe from './en-US/option/iframe'
import img from './en-US/option/img'
import imgborder from './en-US/option/imgborder'
import line from './en-US/option/line'
import main from './en-US/option/main'
import map from './en-US/option/map'
import pictorialbar from './en-US/option/pictorialbar'
import progress from './en-US/option/progress'
import pie from './en-US/option/pie'
import radar from './en-US/option/radar'
import scatter from './en-US/option/scatter'
import table from './en-US/option/table'
import tabs from './en-US/option/tabs'
import text from './en-US/option/text'
import time from './en-US/option/time'
import video from './en-US/option/video'
import swiper from './en-US/option/swiper'
import vue from './en-US/option/vue'
import wordcloud from './en-US/option/wordcloud'
import config from './en-US/option/config'
import code from './en-US/group/code'
import container from './en-US/group/container'
import contentmenu from './en-US/group/contentmenu'
import header from './en-US/group/header'
import imglist from './en-US/group/imglist'
import keys from './en-US/group/keys'
import result from './en-US/group/result'
import share from './en-US/group/share'


export default {
    ...global,
    ...basicList,
    ...bar,
    ...borderBox,
    ...clapper,
    ...common,
    ...datav,
    ...datetime,
    ...decoration,
    ...flop,
    ...funnel,
    ...gauge,
    ...iframe,
    ...img,
    ...imgborder,
    ...line,
    ...main,
    ...map,
    ...pictorialbar,
    ...progress,
    ...pie,
    ...radar,
    ...scatter,
    ...table,
    ...tabs,
    ...text,
    ...time,
    ...video,
    ...vue,
    ...wordcloud,
    ...config,
    ...code,
    ...container,
    ...contentmenu,
    ...header,
    ...imglist,
    ...keys,
    ...result,
    ...share,
    ...swiper
}
