export default {
  groupContentmenu: {
    unlock: 'Unlock',
    lock: 'Lock',
    show: 'Show',
    hide: 'Hide',
    dissolve: 'Dissolve',
    combine: 'Combine',
    delete: 'Delete',
    copy: 'Copy',
    bring_to_front: 'Bring to Front',
    send_to_back: 'Send to Back',
    move_up: 'Move Up',
    move_down: 'Move Down',
    group_selection: 'Group Selection?',
    prompt: 'Prompt',
    confirm: 'Confirm',
    cancel: 'Cancel',
    dissolve_selection: 'Whether to dissolve or not?',
    layer: 'Layer',
    delete_selection: 'Delete Selection?',
  },
};
