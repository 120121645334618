export default {
  groupShare: {
    "share": "分享",
    "view_screen": "查看大屏",
    "copy_link": "复制链接",
    "share_screen": "分享大屏",
    "screen_name": "大屏名称",
    "share_link": "分享链接",
    "share_password": "分享密码",
    "link_copy_success": "链接复制成功",
    "screen_share_success": "大屏分享成功"
    }
};
