export default {
  optionPictorialbar: {
    titleColor: '标题颜色',
    titleSize: '标题大小',
    valueColor: '数值颜色',
    valueSize: '数值大小',
    icon: '图标',
    iconSize: '图标大小',
    spacing: '间距',
  },
};
