export default {
  optionTabs: {
    fontSize: '字体大小',
    fontColor: '字体颜色',
    type: '类型',
    letterSpacing: '字体间距',
    borderSetting: '边框设置',
    backgroundColor: '背景颜色',
    iconSpacing: '图标间距',
    iconSize: '图标大小',
    imageURL: '图片地址',
    borderColor: '边框颜色',
    borderWidth: '边框宽度',
    highlightSetting: '高亮设置',
    highlightFontColor: '字体高亮颜色',
    backgroundImage: '背景图片',
  },
};
