
const state = {
  myGroups:[],
};

const mutations = {
  setState: (state, obj) => {
    Object.keys(obj).forEach((key)=> state[key] = obj[key])
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
