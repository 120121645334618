export default {
  optionMap: {
    mapSelection: '地图选择',
    pleaseSelectMap: '请选择地图',
    mapScale: '地图比例',
    mapZoom: '地图缩放',
    carouselSetting: '轮播设置',
    enableCarousel: '开启轮播',
    carouselTime: '轮播时间',
    basicSetting: '基本设置',
    fontSize: '字体大小',
    fontHighlightColor: '字体高亮颜色',
    fontColor: '字体颜色',
    borderColor: '边框颜色',
    areaSetting: '区域设置',
    areaLine: '区域线',
    areaColor: '区域颜色',
    areaHighlightColor: '区域高亮颜色',
    tooltipSetting: '提示设置',
    backgroundColor: '背景色',
    textColor: '文字颜色',
    textSize: '文字大小',
  },
};
