export default {
  optionProgress: {
    type: '类型',
    width: '宽度',
    color: '颜色',
    backgroundColor: '背景颜色',
    fontSize: '字体大小',
    fontColor: '字体颜色',
    fontWeight: '文字粗细',
    prefixFontSize: '前缀字体大小',
    prefixFontColor: '前缀字体颜色',
    prefixFontWeight: '前缀文字粗细',
  },
};
